import React from "react";

const mapRoutes = [
  {
    path: "/help",
    
    component: React.lazy(() => import("./help"))
  }
];

export default mapRoutes;