import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default function (state = initialState, action) {
    state = Object.assign({}, state);
    switch (action.type) {
        case types.FETCH_USERS_START:
            state.fetch = true;
            state.orguser = [];
            break;
        case types.FETCH_USERS_SUCCESS:
            state.fetch = false;
            break;
        case types.FETCH_USERS_FAIL:
            state.fetch = false;
            state.Error = action.payload.error;
            break;
        case types.FETCH_USERS:
            state.orguser = action.payload.orguser;
            break;

        case types.CREATE_USERS_START:
            state.usercreate = true;
            break;
        case types.CREATE_USERS_SUCCESS:
            state.usercreate = false;
            break;
        case types.CREATE_USERS_FAIL:
            state.usercreate = false;
            break;
        //state.usererr = action.payload.error

        case types.FETCH_USER_ROLES_START:
            state.fetchrole = true;
            state.Roles = []
            break;
        case types.FETCH_USER_ROLES_SUCCESS:
            state.fetchrole = false;
            break;
        case types.FETCH_USER_ROLES_FAIL:
            state.fetchrole = false;
            break;
        case types.FETCH_USER_ROLES:
            state.Roles = action.payload.Roles;
            break;
        case types.FETCH_ORG_LIST_START:
            state.listload = true;
            state.vhlist = []
            break;
        case types.FETCH_ORG_LIST_SUCCESS:
            state.listload = false;
            break;
        case types.FETCH_ORG_LIST_FAIL:
            state.listload = false;
            break
        case types.FETCH_ORG_LIST:
            state.vhlist = action.payload.vhlist;
            break;
        case types.FETCH_USERS_EDIT_START:
            state.orgedit = true
            break;
        case types.FETCH_USERS_EDIT_SUCCESS:
            state.orgedit = false;
            break;
        case types.FETCH_USERS_EDIT_FAIL:
            state.orgedit = false;
            break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.orgusers : state;
            //state.fetch = false;
            //state.Error = undefined;
            //state.usercreate = false;
            // state.usererr = undefined;
            break;
    }
    return state;
}


