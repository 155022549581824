//import lightBaseTheme from "material-ui/styles/baseThemes/lightBaseTheme";
import { blue } from "@material-ui/core/colors";

export default {
    clientWidth: null,
    footerHeight: null,
    initialized: false,
    retina: false,
    theme: blue
};
