
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RaisedButton, Toggle, DropDownMenu, FloatingActionButton } from 'material-ui';
import { Paper, Dialog, IconButton, MenuItem } from '@material-ui/core';
import * as  actions from '../../redux/actions/action-creators';
import { Checkbox, Button, TextField, Select, FormControlLabel, FormControl, InputLabel, withTheme, DialogActions, Input, FormHelperText } from '@material-ui/core';
//import withErrorHandler from 'virtual-manager-lib/hoc/withErrorHandler';
//import muiThemeable from "material-ui/styles/muiThemeable";
import { FlatButton } from "material-ui";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import Typography from "@material-ui/core/Typography";
import { withRouter } from 'react-router-dom';
import RightIcon from "material-ui/svg-icons/hardware/keyboard-arrow-right";
//import './styles.less';
import DialogContent from '@material-ui/core/DialogContent';
import LeftIcon from "material-ui/svg-icons/hardware/keyboard-arrow-left";
//import AppDialog from "../Apps/AppDialog"
import DialogTitle from '@material-ui/core/DialogTitle';
import { black } from 'material-ui/styles/colors';
import Axios from 'axios';
import { functions,storage } from '../../auth/firebaseconfig'

const NOT_ALLOWED_SANDBOX_IDS = ['test'];


class Index extends Component {
    constructor(props) {
        super(props);

        //let isReplica = app.copyType === 'REPLICA';
        this.state = {
            sandboxId: '',
            name: '',
            version: '',
            allowOpen: false,
            applyDefaultDataSet: true,
            applyDefaultApps: true,
            applyCustomApps: true,
            applyUsers: false,
            applySubscriptions: true,
            applyOrg: true,
            description: '',
            createDisabled: true,
            apiEndpointIndex: '7',
            currentStep: 0,
            selectedApp: props.app || null,
            selectedPersona: props.userPersona || null,
            // app,
            //isReplica
            activeStep: 0,
            sandboxType: '',
            tt: '',
            createSandboxFb:'',
        };
    }

    componentDidMount() {
        Axios({
            method: 'get',
            url: 'https://manager-api.collabkare.com/sandboxType/all',
            headers: {
                Authorization: window.fhirClient && window.fhirClient.server && window.fhirClient.server.auth ? `Bearer ${window.fhirClient.server.auth.token}` : undefined
            }
        }).then(res => {
            this.setState({ sandboxType: res })
           // console.log(res)
        }).catch(err => {
            console.log(err)
        })

    }
    render() {
        let notAllowed = this.checkAllowedId();
        let duplicate = this.props.sandboxes.find(i => i.sandboxId.toLowerCase() === this.state.sandboxId.toLowerCase());
        //let actions = this.getActions();
        let steps = getSteps();

        // let actions = [
        //     <RaisedButton key={1} label='Create' disabled={this.state.createDisabled || !!duplicate} className='button' primary onClick={this.handleCreateSandbox} />
        // ];

        // let underlineFocusStyle = { borderColor: this.props.muiTheme.palette.primary2Color };
        // let floatingLabelFocusStyle = { color: this.props.muiTheme.palette.primary2Color };

        return <Dialog open={this.props.open}
        // actions={actions}
        // onClick={this.handleCancel}
        fullWidth={true}
        maxWidth={'sm'}
        >

            <div className='create-sandbox-wrapper'>
                <Paper className='paper-card'>
                    <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>{"Create Healthcare Facility"}
                        <i className="material-icons" style={{ float: 'right' }} onClick={this.handleCancel}>close</i>
                    </DialogTitle>
                    <div className='paper-body' >
                        <DialogContent style={{ color: 'black',marginLeft:'20px' }}>
                            <form>
                                {/* <TextField style={{color:'black'}} id='name' label='Sandbox Name*' value={this.state.name} onChange={this.sandboxNameChangedHandler} data-qa='sandbox-create-name'
                                    onKeyPress={this.submitMaybe} />
                                <div >Must be fewer than 50 characters. e.g., NewCo Sandbox</div>
                                <FormControl className='margin-top'>
                                    <InputLabel htmlFor="id">Sandbox Id*</InputLabel>
                                    <Input id='id' value={this.state.sandboxId} onChange={this.sandboxIdChangedHandler} onKeyPress={this.submitMaybe} />
                                    {!!notAllowed && <FormHelperText error>ID not allowed or already in use</FormHelperText>}
                                </FormControl>
                                <div >Letters and numbers only. Must be fewer than 20 characters.</div>
                                <div >Your sandbox will be available at {window.location.origin}/{this.state.sandboxId}</div>
                                <FormControl style={{ minWidth: '200px', marginTop: '20px' }}>
                                    <InputLabel htmlFor="age-simple">FHIR version</InputLabel>
                                    <Select data-qa='sandbox-version' value={this.state.apiEndpointIndex || ''} onChange={e => this.sandboxFhirVersionChangedHandler('apiEndpointIndex', e.target.value)}
                                        inputProps={{ name: 'age', id: 'age-simple' }} >

                                        <MenuItem value='5' data-qa='fhir-dstu2'>
                                            FHIR DSTU2 (v1.0.2)
                                    </MenuItem>
                                        <MenuItem value='6' data-qa='fhir-stu3'>
                                            FHIR STU3 (v3.0.1)
                                    </MenuItem>
                                        <MenuItem value='7' data-qa='fhir-r4'>
                                            FHIR R4 (v3.4.0)
                                    </MenuItem>
                                    </Select>
                                </FormControl>
                                <div>Choose a version of the FHIR Standard</div>
                                <br />
                                <div >
                                    <div>
                                        <FormControlLabel control={<Checkbox onChange={this.allowOpenChangeHandler} value='open' color='primary' />} label='Allow Open FHIR Endpoint' />
                                    </div>
                                    <div>
                                        <FormControlLabel control={<Checkbox defaultChecked onChange={this.applyDefaultChangeHandler} value='open' color='primary' />} label='Import sample patients and practitioners'
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel control={<Checkbox defaultChecked onChange={this.applyDefaultAppsChangeHandler} value='open' color='primary' />} label='Import sample applications'
                                        />
                                    </div>
                                </div>
                                <TextField id='description' label='Description' onChange={this.sandboxDescriptionChange} data-qa='sandbox-create-description' onKeyPress={this.submitMaybe} /><br />
                                <div >e.g., This sandbox is the QA environment for NewCo.</div>

                              
                              <DialogActions>
                              <Button onClick={this.handleCreateSandbox}  disabled={this.state.createDisabled || !!duplicate} type='submit' variant='outlined' >create</Button>
                            </DialogActions> */}
                                <div>
                                    <Stepper activeStep={this.state.currentStep} alternativeLabel>
                                        {steps.map(label => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <div>
                                        {/* {this.state.currentStep === steps.length ? (
                                            <div>

                                                <Button
                                                    className="mt-4"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={this.handleReset}
                                                >
                                                    Reset
                                                 </Button>
                                            </div>
                                        ) : ( */}
                                        <div>
                                            {this.getStepContent(this.state.currentStep)}
                                            <DialogActions>
                                            <div className="pt-4">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled={this.state.currentStep === 0}
                                                    onClick={this.handleBack}
                                                >
                                                    Back
                                                        </Button>&nbsp;

                                                {this.state.currentStep === 2 ?
                                                    <Button onClick={this.handleCreateSandbox}
                                                        disabled={this.state.createDisabled || !!duplicate}
                                                        //type='submit' 
                                                        variant='outlined' >create</Button> :  
                                                  <Button
                                                        className="ml-4"
                                                        variant="contained"
                                                        color="primary"


                                                        disabled={!(this.state.currentStep === 0
                                                            ? !!this.state.sandboxId && !!this.state.tt
                                                            : this.state.currentStep === 1
                                                                ? !!this.state.apiEndpointIndex
                                                                : this.state.currentStep === 2
                                                                    ? !this.props.singleEncounterLoadingError && !this.props.singleLocationLoadingError && !this.props.singleIntentLoadingError
                                                                    && !this.props.singleResourceLoadingError && !this.props.fetchingSinglePatientError
                                                                    : this.state.description
                                                                        ? !!this.state.applyUsers
                                                                        : this.state.currentStep === 3)}

                                                        onClick={this.handleNext}
                                                    >


                                                        Next
                                                     </Button>}
                                            </div>
                                            </DialogActions>
                                        </div>
                                        {/* )}  */}
                                    </div>
                                </div>

                            </form>
                        </DialogContent>
                    </div>
                </Paper>

            </div>
        </Dialog >
    }
    getStepContent(currentStep) {
        let notAllowed = this.checkAllowedId();
        let duplicate = this.props.sandboxes.find(i => i.sandboxId.toLowerCase() === this.state.sandboxId.toLowerCase());
        switch (currentStep) {
            case 0:
                return <div>
                    <TextField style={{ color: 'black' }} id='name' label='Sandbox Name*' value={this.state.name} onChange={this.sandboxNameChangedHandler} data-qa='sandbox-create-name'
                        onKeyPress={this.submitMaybe} />
                    <div >Must be fewer than 50 characters. e.g., NewCo Sandbox</div>
                    <FormControl style={{ minWidth: '200px', marginTop: '20px' }}>
                        <InputLabel htmlFor="type">Healthcare Facility*</InputLabel>
                        <Select data-qa='sandbox-version'
                            value={this.state.tt || ''}
                            onChange={e => this.sandboxFhirVersionChangedHandler('tt', e.target.value)}
                            inputProps={{ name: 'typ', id: 'type' }} >

                            {this.state.sandboxType !== '' && this.state.sandboxType !== undefined ?
                                this.state.sandboxType.data.map(res => <MenuItem key={res.id} value={res} data-qa='fhir-sandbox'>
                                    {res.sandbox_type}
                                </MenuItem>) : ''}

                        </Select>
                    </FormControl>
                    <div>Choose type of Healthcare Facility</div>




                    <FormControl className='margin-top'>
                        <InputLabel htmlFor="id">Sandbox Id*</InputLabel>
                        <Input id='id' value={this.state.sandboxId} onChange={this.sandboxIdChangedHandler} onKeyPress={this.submitMaybe} />
                        {!!notAllowed && <FormHelperText error>ID not allowed or already in use</FormHelperText>}
                    </FormControl>
                    <div >Letters and numbers only. Must be fewer than 20 characters.</div>
                    <div >Your sandbox will be available at {window.location.origin}/{this.state.sandboxId}</div>
                    <TextField id='description' label='Description' onChange={this.sandboxDescriptionChange} data-qa='sandbox-create-description' onKeyPress={this.submitMaybe} /><br />
                    <div >e.g., This sandbox is the QA environment for NewCo.</div>
                </div>
            case 1:
                return <div>
                    <FormControl style={{ minWidth: '200px', marginTop: '20px' }}>
                        <InputLabel htmlFor="age-simple">FHIR version</InputLabel>
                        <Select data-qa='sandbox-version' value={this.state.apiEndpointIndex || ''} onChange={e => this.sandboxFhirVersionChangedHandler('apiEndpointIndex', e.target.value)}
                            inputProps={{ name: 'age', id: 'age-simple' }} >

                            {/* <MenuItem value='5' data-qa='fhir-dstu2'>
                                FHIR DSTU2 (v1.0.2)
                                    </MenuItem>
                            <MenuItem value='6' data-qa='fhir-stu3'>
                                FHIR STU3 (v3.0.1)
                                    </MenuItem> */}
                            <MenuItem value='7' data-qa='fhir-r4'>
                                FHIR R4 (v3.4.0)
                                    </MenuItem>
                        </Select>
                    </FormControl>
                    <div>Choose a version of the FHIR Standard</div>
                    <br />
                    <div >
                        <div>
                            <FormControlLabel control={<Checkbox disabled onChange={this.allowOpenChangeHandler} value='open' color='primary' />} label='Allow Open FHIR Endpoint' />
                        </div>
                        {/* <div>
                            <FormControlLabel control={<Checkbox defaultChecked onChange={this.applyDefaultChangeHandler} value='open' color='primary' />} label='Import sample patients and practitioners'
                            />
                        </div> */}
                        {/* <div>
                            <FormControlLabel control={<Checkbox defaultChecked onChange={this.applyDefaultAppsChangeHandler} value='open' color='primary' />} label='Import sample applications'
                            />
                        </div> */}
                    </div>
                </div>
            case 2:
                return <div className='checkboxes'>

                    <div>
                        <FormControlLabel control={<Checkbox defaultChecked onChange={this.applyDefaultAppsChangeHandler} value='open' color='primary' />} label='Import sample applications'
                        />
                    </div>
                    {/* <Checkbox label='Import subscriptions' className='checkbox' defaultChecked onCheck={this.applyDefaultSubChangeHandler}/>
                                 <Checkbox label='Import Organization' className='checkbox' defaultChecked onCheck={this.applyDefaultOrgChangeHandler}/> */}
                </div>;

            // case 3:
            //     return <div style={{ marginLeft: '35px' }}>
            //         <FormControl style={{ minWidth: '200px', marginTop: '20px' }}>
            //             <InputLabel htmlFor="age-simple">Choose Role</InputLabel>
            //             <Select data-qa='sandbox-version'
            //                 value={'8'}
            //                 //value={this.state.apiEndpointIndex || ''} 
            //                 // onChange={e => this.sandboxFhirVersionChangedHandler('apiEndpointIndex', e.target.value)}
            //                 inputProps={{ name: 'age', id: 'age-simple' }} >

            //                 <MenuItem value='8' data-qa='fhir-dstu2'>Cheep Hospitalist</MenuItem>
            //                 <MenuItem value='9' data-qa='fhir-stu3'>Program-co-ordinatior</MenuItem>
            //                 <MenuItem value='100' data-qa='fhir-r4'>Resident</MenuItem>
            //                 <MenuItem value='101' data-qa='fhir-r4'> Consultant</MenuItem>
            //                 <MenuItem value='102' data-qa='fhir-r4'>Nurse Practioner</MenuItem>
            //             </Select>
            //         </FormControl>
            //         <br />
            //         <TextField id='name' label='name'
            //             //onChange={this.sandboxDescriptionChange}
            //             data-qa='sandbox-create-name' onKeyPress={this.submitMaybe} /><br />
            //         <TextField id='email' label='email'
            //             //onChange={this.sandboxDescriptionChange}
            //             data-qa='sandbox-create-email' onKeyPress={this.submitMaybe} /><br />
            //     </div>
        }
    }


    next = () => {
        let currentStep = this.state.currentStep + 1;
        let state = { currentStep };
        if (currentStep === 1) {
            return this.props.apiEndpointIndex;
        }
        //  else if (this.state.titleIsClean && currentStep === 3 && !this.state.id) {
        //     let title = `Launch ${this.state.selectedApp.clientName}`;
        //     title += (this.props.singlePatient ? ` with ${getPatientName(this.props.singlePatient)}` : '');
        //     title += ` as ${this.getSelectedName()}`;
        //     state.title = title;
        // }
        console.log(state)
        this.setState(state)
    };

    prev = () => {
        this.setState({ currentStep: this.state.currentStep - 1 })
    };
    getActions = () => {
        let nextEnabled = this.state.currentStep === 0
            ? !!this.state.sandboxId
            : this.state.currentStep === 1
                ? !!this.state.apiEndpointIndex
                : this.state.currentStep === 2
                    ? !this.props.singleEncounterLoadingError && !this.props.singleLocationLoadingError && !this.props.singleIntentLoadingError
                    && !this.props.singleResourceLoadingError && !this.props.fetchingSinglePatientError
                    : this.state.description.length > 2
                        ? !!this.state.applyUsers
                        : this.state.currentStep === 3;

        let nextColor = nextEnabled ? 'blue' : 'black';
        let prevColor = 'blue';

        let actions = this.state.currentStep !== 3
            ? <Button
                //disabled={!nextEnabled} 
                //labelPosition="before" 
                style={{ color: nextColor }}
                //startIcon={<RightIcon />}
                onClick={this.next} > NEXT</Button>
            : <Button disabled={!nextEnabled}
                primary onClick={this.handleCreateSandbox} >CREATE</Button>;

        if (this.state.currentStep > 0) {
            actions.unshift(
                <Button
                    // endIcon={<LeftIcon style={{ color: prevColor, marginLeft: '-75px', marginRight: '26px' }} />}
                    // labelPosition="before" 
                    style={{ color: prevColor }} onClick={this.prev} >BACK</Button>
            );
        }

        return actions;
    };


    sandboxDescriptionChange = (_e, description) => {
        this.setState({ description });
    };

    handleCreateSandbox = (event) => {
        event.preventDefault();
        let createRequest = {
            createdBy: this.props.user,
            name: this.state.name.length === 0 ? this.state.sandboxId : this.state.name,
            sandboxId: this.state.sandboxId,
            description: this.state.description,
            dataSet: this.state.applyDefaultDataSet ? 'DEFAULT' : 'NONE',
            apps: this.state.applyDefaultApps ? 'DEFAULT' : 'NONE',
            //data: this.state.applySubscriptions ? 'DEFAULT' : 'NONE',
            // Organization: this.state.applyOrg ? 'DEFAULT' : 'NONE',
            apiEndpointIndex: this.state.apiEndpointIndex,
            allowOpenAccess: this.state.allowOpen,
            users: [this.props.user],
            sandboxType: this.state.tt
        };
        const email = createRequest?.createdBy?.sbmUserId;
        const domain = email.split("@")[1];
        const domainWithoutTLD = domain.slice(0, domain.indexOf("."));
        let facility= createRequest?.sandboxType?.sandbox_type
        localStorage.setItem('organization', domainWithoutTLD)

        let fbData={
            ...createRequest,
            organization:domainWithoutTLD,
            facility:facility
        }
        this.createSandboxFb(fbData)
        this.props.createSandbox(createRequest);
        console.log(fbData,"createRequest-----" + JSON.stringify(createRequest));
        this.props.onCancel && this.props.onCancel();
    };

    createSandboxFb= async (data)=>{
        try {
            // Get a reference to the Firebase Cloud Function
            const createOrgnisation =
            functions.httpsCallable('CreateOrganization')
            // Call the Firebase Cloud Function with the email data
            const response = await createOrgnisation(data)
            if (response) {
                // handleClose()
                // setShowSnackBar(true)
                // setSnackBarMsg({
                //     msgType: 'success',
                //     msg: response?.data?.message,
                // })
            }
        } catch (error) {
            console.error('Failed to create Patient:', error)
            // setShowSnackBar(true)
            // setSnackBarMsg({
            //     msgType: 'error',
            //     msg: 'Failed to create Patient',
            // })
        }
    }

    allowOpenChangeHandler = () => {
        this.setState((oldState) => {
            return {
                allowOpen: !oldState.checked,
            };
        });
    };
    checkAllowedId = () => {
        return this.props.sandboxes.find(i => i.sandboxId.toLowerCase() === this.state.sandboxId.toLowerCase()) || NOT_ALLOWED_SANDBOX_IDS.indexOf(this.state.sandboxId.toLowerCase()) >= 0;
    };
    applyDefaultChangeHandler = () => {
        this.setState({ applyDefaultDataSet: !this.state.applyDefaultDataSet });
    };
    customAppsChangeHandler = (_, applyCustomApps) => {
        this.setState({ applyCustomApps });
    };
    applyDefaultAppsChangeHandler = (_, applyDefaultApps) => {
        this.setState({ applyDefaultApps });
    };
    applyUsersChangeHandler = (_, applyUsers) => {
        this.setState({ applyUsers });
    };
    applyDefaultSubChangeHandler = (_, applySubscriptions) => {
        this.setState({ applySubscriptions });
    };
    applyDefaultOrgChangeHandler = (_, applyOrg) => {
        this.setState({ applyOrg });
    };

    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
    };

    sandboxIdChangedHandler = (event) => {
        let value = event.target.value.replace(/[^a-z0-9]/gi, '');
        if (value.length > 20) {
            value = value.substring(0, 20);
        }
        this.setState({ sandboxId: value, createDisabled: value === 0 })
    };

    sandboxNameChangedHandler = (event) => {
        let value = event.target.value;
        if (value.length > 50) {
            value = value.substring(0, 50);
        }
        let cleanValue = value.replace(/[^a-z0-9]/gi, '');
        if (cleanValue.length > 20) {
            cleanValue = cleanValue.substring(0, 20);
        }
        this.setState({ name: value, sandboxId: cleanValue, createDisabled: value === 0 });
    };

    sandboxFhirVersionChangedHandler = (prop, val) => {
        let sandbox = this.state || this.props || {};
        sandbox[prop] = val;
        this.setState({ sandbox });
    };

    handleNext = () => {
        this.setState(state => ({
            currentStep: state.currentStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            currentStep: state.currentStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            currentStep: 0,
        });
    };

}

const mapStateToProps = state => {
    return {
        user: state.users.oauthUser,
        sandboxes: state.sandbox.sandboxes
    };
};


const mapDispatchToProps = dispatch => {
    return {
        createSandbox: (sandboxDetails) => dispatch(actions.createSandbox(sandboxDetails))
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)((Index)));
function getSteps() {
    return [
        "create hospital name",
        "choose fhir version",
        "select app",
      // "user"
    ];
}


// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { RaisedButton, Toggle, DropDownMenu, FloatingActionButton } from 'material-ui';
// import { Paper, Dialog, IconButton, MenuItem } from '@material-ui/core';
// import * as  actions from '../../redux/actions/action-creators';
// import { Checkbox, Button, TextField, Select, FormControlLabel, FormControl, InputLabel, withTheme, DialogActions, Input, FormHelperText } from '@material-ui/core';
// //import withErrorHandler from 'virtual-manager-lib/hoc/withErrorHandler';
// //import muiThemeable from "material-ui/styles/muiThemeable";
// import { Step, StepLabel, Stepper, FlatButton } from "material-ui";
// import { withRouter } from 'react-router';
// import RightIcon from "material-ui/svg-icons/hardware/keyboard-arrow-right";
// //import './styles.less';
// import DialogContent from '@material-ui/core/DialogContent';
// import LeftIcon from "material-ui/svg-icons/hardware/keyboard-arrow-left";
// //import AppDialog from "../Apps/AppDialog"

// import DialogTitle from '@material-ui/core/DialogTitle';
// const NOT_ALLOWED_SANDBOX_IDS = ['test'];
// class Index extends Component {
//     constructor(props) {
//         super(props);

//         //let isReplica = app.copyType === 'REPLICA';
//         this.state = {
//             sandboxId: '',
//             name: '',
//             version: '',
//             allowOpen: false,
//             applyDefaultDataSet: true,
//             applyDefaultApps: true,
//             applyCustomApps: true,
//             applyUsers: false,
//             applySubscriptions: true,
//             applyOrg: true,
//             description: '',
//             createDisabled: true,
//             apiEndpointIndex: '6',
//             currentStep: 0,
//             selectedApp: props.app || null,
//             selectedPersona: props.userPersona || null,
//             // app,
//             //isReplica
//         };
//     }

//     render() {
//          let notAllowed = this.checkAllowedId();
//          let duplicate = this.props.sandboxes.find(i => i.sandboxId.toLowerCase() === this.state.sandboxId.toLowerCase());
//         let actions = this.getActions();
//         // let actions = [
//         //     <RaisedButton key={1} label='Create' disabled={this.state.createDisabled || !!duplicate} className='button' primary onClick={this.handleCreateSandbox} />
//         // ];

//         // let underlineFocusStyle = { borderColor: this.props.muiTheme.palette.primary2Color };
//         // let floatingLabelFocusStyle = { color: this.props.muiTheme.palette.primary2Color };

//         return <Dialog open={this.props.open} actions={actions}
//         // onClick={this.handleCancel}
//         >
//             <div className='create-sandbox-wrapper'>
//                 <Paper className='paper-card'>
//                     <DialogTitle id="alert-dialog-slide-title" style={{textAlign:'center'}}>{"Create Virtual Hospital"}
//                          <i className="material-icons"  style={{float:'right'}} onClick={this.handleCancel}>close</i>
//                     </DialogTitle>
//                     <div className='paper-body'>
//                         <DialogContent style={{color:'black'}}>
//                             <form>
//                                 <TextField style={{color:'black'}} id='name' label='Sandbox Name*' value={this.state.name} onChange={this.sandboxNameChangedHandler} data-qa='sandbox-create-name'
//                                     onKeyPress={this.submitMaybe} />
//                                 <div >Must be fewer than 50 characters. e.g., NewCo Sandbox</div>
//                                 <FormControl className='margin-top'>
//                                     <InputLabel htmlFor="id">Sandbox Id*</InputLabel>
//                                     <Input id='id' value={this.state.sandboxId} onChange={this.sandboxIdChangedHandler} onKeyPress={this.submitMaybe} />
//                                     {!!notAllowed && <FormHelperText error>ID not allowed or already in use</FormHelperText>}
//                                 </FormControl>
//                                 <div >Letters and numbers only. Must be fewer than 20 characters.</div>
//                                 <div >Your sandbox will be available at {window.location.origin}/{this.state.sandboxId}</div>
//                                 <FormControl style={{ minWidth: '200px', marginTop: '20px' }}>
//                                     <InputLabel htmlFor="age-simple">FHIR version</InputLabel>
//                                     <Select data-qa='sandbox-version' value={this.state.apiEndpointIndex || ''} onChange={e => this.sandboxFhirVersionChangedHandler('apiEndpointIndex', e.target.value)}
//                                         inputProps={{ name: 'age', id: 'age-simple' }} >

//                                         <MenuItem value='5' data-qa='fhir-dstu2'>
//                                             FHIR DSTU2 (v1.0.2)
//                                     </MenuItem>
//                                         <MenuItem value='6' data-qa='fhir-stu3'>
//                                             FHIR STU3 (v3.0.1)
//                                     </MenuItem>
//                                         <MenuItem value='7' data-qa='fhir-r4'>
//                                             FHIR R4 (v3.4.0)
//                                     </MenuItem>
//                                     </Select>
//                                 </FormControl>
//                                 <div>Choose a version of the FHIR Standard</div>
//                                 <br />
//                                 <div >
//                                     <div>
//                                         <FormControlLabel control={<Checkbox onChange={this.allowOpenChangeHandler} value='open' color='primary' />} label='Allow Open FHIR Endpoint' />
//                                     </div>
//                                     <div>
//                                         <FormControlLabel control={<Checkbox defaultChecked onChange={this.applyDefaultChangeHandler} value='open' color='primary' />} label='Import sample patients and practitioners'
//                                         />
//                                     </div>
//                                     <div>
//                                         <FormControlLabel control={<Checkbox defaultChecked onChange={this.applyDefaultAppsChangeHandler} value='open' color='primary' />} label='Import sample applications'
//                                         />
//                                     </div>
//                                 </div>
//                                 <TextField id='description' label='Description' onChange={this.sandboxDescriptionChange} data-qa='sandbox-create-description' onKeyPress={this.submitMaybe} /><br />
//                                 <div >e.g., This sandbox is the QA environment for NewCo.</div>

//                                 {/* <div className='stepper'>
//                                 <Stepper activeStep={this.state.currentStep}>
//                                     <Step>
//                                         <StepLabel className='step-label'>Create Hospital Name</StepLabel>
//                                     </Step>
//                                     <Step>
//                                         <StepLabel className='step-label'>Choose Fhir Version</StepLabel>
//                                     </Step>
//                                     <Step>
//                                         <StepLabel className='step-label'>Select App</StepLabel>
//                                     </Step>

//                                     <Step>
//                                         <StepLabel className='step-label'>User</StepLabel>
//                                     </Step>

//                                 </Stepper>
//                             </div> */}
//                                 {/* <div className='create-scenario-content-wrapper'>
//                                // {this.getContent()}
//                             </div> */}
//                               <DialogActions>
//                               <Button onClick={this.handleCreateSandbox}  disabled={this.state.createDisabled || !!duplicate} type='submit' variant='outlined' >create</Button>
//                             </DialogActions>

//                             </form>
//                         </DialogContent>
//                     </div>
//                 </Paper>

//             </div>
//         </Dialog>
//     }
//     getContent = () => {
//         let duplicate = this.props.sandboxes.find(i => i.sandboxId.toLowerCase() === this.state.sandboxId.toLowerCase());

//         let underlineFocusStyle = { borderColor: 'blue' };
//         let floatingLabelFocusStyle = { color: 'blue' };
//         let clientId = null;
//         let clientSecret = null;
//         //let theme = this.props.muiTheme.palette;


//         switch (this.state.currentStep) {
//             case 0:
//                 return <div> <TextField id='name' floatingLabelText='Virtual Hospital Name*' value={this.state.name} onChange={this.sandboxNameChangedHandler}
//                     underlineFocusStyle={underlineFocusStyle} floatingLabelFocusStyle={floatingLabelFocusStyle} /> <br />
//                     <div className='subscript'>Must be fewer than 50 characters. e.g., NewCo Virtual Hospital</div>
//                     <TextField id='id' floatingLabelText='Virtual Hospital Id*' value={this.state.sandboxId} onChange={this.sandboxIdChangedHandler}
//                         errorText={duplicate ? 'ID already in use' : undefined} underlineFocusStyle={underlineFocusStyle} floatingLabelFocusStyle={floatingLabelFocusStyle} /><br />
//                     <div className='subscript'>Letters and numbers only. Must be fewer than 20 characters.</div>
//                     <div className='subscript'>Your Virtual Hospital will be available at {window.location.origin}/{this.state.sandboxId}
//                     </div>
//                     <TextField id='description' floatingLabelText='Description' onChange={this.sandboxDescriptionChange}
//                         underlineFocusStyle={underlineFocusStyle} floatingLabelFocusStyle={floatingLabelFocusStyle} /><br />
//                     <div className='subscript'>e.g., This Virtual Hospital is the QA environment for NewCo.
//                             </div>
//                 </div>;


//             case 1:
//                 return <div> <DropDownMenu value={this.state.apiEndpointIndex} onChange={(_e, _k, value) => this.sandboxFhirVersionChangedHandler('apiEndpointIndex', value)}
//                     className='fhirVersion'>
//                     <MenuItem value='5' primaryText='FHIR DSTU2 (v1.0.2)' />
//                     <MenuItem value='6' primaryText='FHIR STU3 (v3.0.1)' />
//                     <MenuItem value='7' primaryText='FHIR R4 (v3.4.0)' />
//                 </DropDownMenu>
//                     <div className='subscript'>Choose a version of the FHIR Standard</div>
//                     <div className='checkboxes'>
//                         <Checkbox label='Allow Open FHIR Endpoint' className='checkbox' onCheck={this.allowOpenChangeHandler} />
//                         <Checkbox label='Import sample patients and practitioners' className='checkbox' defaultChecked onCheck={this.applyDefaultChangeHandler} />
//                         <Checkbox label='Import sample IGs' className='checkbox' defaultChecked onCheck={this.applyDefaultChangeHandler} />
//                     </div>
//                 </div>;

//             case 2:
//                 return <div> <div className='checkboxes'>

//                     <Checkbox label='Import sample applications' className='checkbox' defaultChecked onCheck={this.applyDefaultAppsChangeHandler} />
//                     {/* <Checkbox label='Import subscriptions' className='checkbox' defaultChecked onCheck={this.applyDefaultSubChangeHandler}/>
//                                 <Checkbox label='Import Organization' className='checkbox' defaultChecked onCheck={this.applyDefaultOrgChangeHandler}/> */}
//                 </div></div>;

//             case 3:
//                 return <div>
//                     <div style={{ marginLeft: '35px' }}>
//                         <form>
//                             <div style={{ color: 'rgba(0, 0, 0, 0.3)', display: 'inline-block', transform: 'translate(0, -20%)', height: '110px' }}>Choose Role</div>
//                             <DropDownMenu value={this.state.apiEndpointIndex} onChange={(_e, _k, value) => this.sandboxFhirVersionChangedHandler('apiEndpointIndex', value)}
//                             // style={{ height: '45px' }}
//                             >
//                                 <MenuItem value='5' primaryText='Cheep Hospitalist' />
//                                 <MenuItem value='6' primaryText='Program-co-ordinatior' />
//                                 <MenuItem value='7' primaryText='Resident' />
//                                 <MenuItem value='8' primaryText='Consultant' />
//                                 <MenuItem value='9' primaryText='Nurse Practioner' />
//                             </DropDownMenu>
//                             <div style={{ marginTop: '-97px' }}>
//                                 <TextField id='name' floatingLabelText='Name*' onChange={this.applyUsersChangeHandler}
//                                     underlineFocusStyle={underlineFocusStyle} floatingLabelFocusStyle={floatingLabelFocusStyle} /><br />
//                                 <TextField id='email' floatingLabelText='Email*' onChange={this.applyUsersChangeHandler}
//                                     underlineFocusStyle={underlineFocusStyle} floatingLabelFocusStyle={floatingLabelFocusStyle} /><br />
//                             </div>
//                         </form>
//                     </div>
//                 </div>;
//         }
//     };


//     next = () => {
//         let currentStep = this.state.currentStep + 1;
//         let state = { currentStep };
//         if (currentStep === 1) {
//             return this.props.apiEndpointIndex;
//         }
//         //  else if (this.state.titleIsClean && currentStep === 3 && !this.state.id) {
//         //     let title = `Launch ${this.state.selectedApp.clientName}`;
//         //     title += (this.props.singlePatient ? ` with ${getPatientName(this.props.singlePatient)}` : '');
//         //     title += ` as ${this.getSelectedName()}`;
//         //     state.title = title;
//         // }
//         this.setState(state)
//     };

//     prev = () => {
//         this.setState({ currentStep: this.state.currentStep - 1 })
//     };
//     getActions = () => {
//         let nextEnabled = this.state.currentStep === 0
//             ? !!this.state.sandboxId
//             : this.state.currentStep === 1
//                 ? !!this.state.apiEndpointIndex
//                 : this.state.currentStep === 2
//                     ? !this.props.singleEncounterLoadingError && !this.props.singleLocationLoadingError && !this.props.singleIntentLoadingError
//                     && !this.props.singleResourceLoadingError && !this.props.fetchingSinglePatientError
//                     : this.state.description.length > 2
//                         ? !!this.state.applyUsers
//                         : this.state.currentStep === 3;

//         let nextColor = nextEnabled ? 'blue' : 'black';
//         let prevColor = 'blue';

//         let actions = this.state.currentStep !== 3
//             ? [<FlatButton disabled={!nextEnabled} label="NEXT" labelPosition="before" style={{ color: nextColor }} icon={<RightIcon />} onClick={this.next} />]
//             : [<RaisedButton disabled={!nextEnabled} label="CREATE" primary onClick={this.handleCreateSandbox} />];

//         if (this.state.currentStep > 0) {
//             actions.unshift(
//                 <FlatButton label="BACK" icon={<LeftIcon style={{ color: prevColor, marginLeft: '-75px', marginRight: '26px' }} />} labelPosition="before" style={{ color: prevColor }} onClick={this.prev} />
//             );
//         }

//         return actions;
//     };


//     sandboxDescriptionChange = (_e, description) => {
//         this.setState({ description });
//     };

//     handleCreateSandbox = (event) => {
//         event.preventDefault();
//         let createRequest = {
//             createdBy: this.props.user,
//             name: this.state.name.length === 0 ? this.state.sandboxId : this.state.name,
//             sandboxId: this.state.sandboxId,
//             description: this.state.description,
//             dataSet: this.state.applyDefaultDataSet ? 'DEFAULT' : 'NONE',
//             apps: this.state.applyDefaultApps ? 'DEFAULT' : 'NONE',
//             //data: this.state.applySubscriptions ? 'DEFAULT' : 'NONE',
//             // Organization: this.state.applyOrg ? 'DEFAULT' : 'NONE',
//             apiEndpointIndex: this.state.apiEndpointIndex,
//             allowOpenAccess: this.state.allowOpen,
//             users: [this.props.user]
//         };
//         this.props.createSandbox(createRequest);
//         console.log("createRequest-----" + createRequest);
//         this.props.onCancel && this.props.onCancel();
//     };

//     allowOpenChangeHandler = () => {
//         this.setState((oldState) => {
//             return {
//                 allowOpen: !oldState.checked,
//             };
//         });
//     };
//     checkAllowedId = () => {
//                 return this.props.sandboxes.find(i => i.sandboxId.toLowerCase() === this.state.sandboxId.toLowerCase()) || NOT_ALLOWED_SANDBOX_IDS.indexOf(this.state.sandboxId.toLowerCase()) >= 0;
//             };
//     applyDefaultChangeHandler = () => {
//         this.setState({ applyDefaultDataSet: !this.state.applyDefaultDataSet });
//     };
//     customAppsChangeHandler = (_, applyCustomApps) => {
//         this.setState({ applyCustomApps });
//     };
//     applyDefaultAppsChangeHandler = (_, applyDefaultApps) => {
//         this.setState({ applyDefaultApps });
//     };
//     applyUsersChangeHandler = (_, applyUsers) => {
//         this.setState({ applyUsers });
//     };
//     applyDefaultSubChangeHandler = (_, applySubscriptions) => {
//         this.setState({ applySubscriptions });
//     };
//     applyDefaultOrgChangeHandler = (_, applyOrg) => {
//         this.setState({ applyOrg });
//     };

//     handleCancel = () => {
//         this.props.onCancel && this.props.onCancel();
//     };

//     sandboxIdChangedHandler = (event) => {
//         let value = event.target.value.replace(/[^a-z0-9]/gi, '');
//         if (value.length > 20) {
//             value = value.substring(0, 20);
//         }
//         this.setState({ sandboxId: value, createDisabled: value === 0 })
//     };

//     sandboxNameChangedHandler = (event) => {
//         let value = event.target.value;
//         if (value.length > 50) {
//             value = value.substring(0, 50);
//         }
//         let cleanValue = value.replace(/[^a-z0-9]/gi, '');
//         if (cleanValue.length > 20) {
//             cleanValue = cleanValue.substring(0, 20);
//         }
//         this.setState({ name: value, sandboxId: cleanValue, createDisabled: value === 0 });
//     };

//     sandboxFhirVersionChangedHandler = (prop, val) => {
//         let sandbox = this.state || this.props || {};
//         sandbox[prop] = val;
//         this.setState({ sandbox });
//     };

// }

// const mapStateToProps = state => {
//     return {
//         user: state.users.oauthUser,
//         sandboxes: state.sandbox.sandboxes
//     };
// };


// const mapDispatchToProps = dispatch => {
//     return {
//         createSandbox: (sandboxDetails) => dispatch(actions.createSandbox(sandboxDetails))
//     };
// };


// export default withRouter(connect(mapStateToProps, mapDispatchToProps)((Index)));
