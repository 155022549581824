// import React, {Component} from 'react';
// import {Paper, Button, List, ListItem, Avatar, IconButton, CircularProgress, Select, MenuItem, ListItemIcon, ListItemText} from '@material-ui/core';
// import {withTheme} from '@material-ui/styles';
// import {fetchSandboxes, selectSandbox, getLoginInfo} from "../../redux/actions/action-creators"

// import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
// //import withErrorHandler from '../../../UI/hoc/withErrorHandler';
// import {withRouter} from 'react-router';
// import {Lock, Public, Sort} from "@material-ui/icons";

// //import './styles.less';

// const SORT_VALUES = [
//     {val: 'last_used', label: 'Last Used'},
//     {val: 'alphabetical', label: 'Alphabetical'}
// ];
// let mainTimers = {};

// class Index extends Component {

//     constructor(props) {
//         super(props);

//         this.state = {
//             sort: 'last_used',
//             desc: true,
//             timers: {}
//         };
//     }

//     componentDidMount() {
//         sessionStorage.clear();
//     }

//     componentDidUpdate(prevProps) {
//         (this.props.creatingSandboxInfo || []).map((si, i) => {
//             let prev = prevProps.creatingSandboxInfo ? prevProps.creatingSandboxInfo.find(a => a.sandboxId === si.sandboxId) : undefined;
//             if (!prev || prev.queuePosition !== si.queuePosition) {
//                 if (!mainTimers[si.sandboxId]) {
//                     let timers = Object.assign({}, this.state.timers);
//                     timers[si.sandboxId] = (si.queuePosition || 0) * 15 + 15;
//                     this.setState({timers});

//                     mainTimers[si.sandboxId] = setInterval(() => {
//                         let timers = Object.assign({}, this.state.timers);
//                         timers[si.sandboxId] = timers[si.sandboxId] - 1;
//                         timers[si.sandboxId] >= 0
//                             ? this.setState({timers})
//                             : clearInterval(mainTimers[si.sandboxId]) && delete mainTimers[si.sandboxId];
//                     }, 1000);
//                 } else {
//                     let timers = Object.assign({}, this.state.timers);
//                     timers[si.sandboxId] = (si.queuePosition || 0) * 15 + 15;
//                     this.setState({timers});
//                 }
//             }
//         });
//     }

//     render() {
//         let sandboxes = [];
//         if (!this.props.loading) {
//             let list = this.sortSandboxes();
//             let loadingSandboxes = this.props.isSandboxCreating && this.props.creatingSandboxInfo
//                 ? this.props.creatingSandboxInfo
//                 : [];
//             // console.log(loadingSandboxes);

//             sandboxes = list.map((sandbox, index) => {
//                 if (sandbox.creationStatus === 'CREATED') {
//                     let {avatarClasses, backgroundColor, avatarText} = this.getAvatarInfo(sandbox.apiEndpointIndex);
//                     let leftAvatar = <Avatar className={avatarClasses} style={{backgroundColor}}>{avatarText}</Avatar>;
//                     let rightIcon = sandbox.allowOpenAccess
//                         ? <IconButton tooltip='Open endpoint'>
//                             <Public style={{fill: this.props.theme.p3}}/>
//                         </IconButton>
//                         : <IconButton tooltip='Authorization required'>
//                             <Lock style={{fill: this.props.theme.p3}}/>
//                         </IconButton>;
//                     return <a key={index} href={`${window.location.origin}/${sandbox.sandboxId}/apps`} onClick={e => e.preventDefault()} style={{textDecoration: 'none'}}>
//                         <ListItem data-qa={`sandbox-${sandbox.sandboxId}`} onClick={() => this.selectSandbox(index)} id={sandbox.name} button>
//                             <ListItemIcon>
//                                 {leftAvatar}
//                             </ListItemIcon>
//                             <ListItemText primary={<span style={{color: this.props.theme.p6}}>{sandbox.name}</span>} secondary={sandbox.description || 'no description available'}/>
//                             <ListItemIcon>
//                                 {rightIcon}
//                             </ListItemIcon>
//                         </ListItem>
//                     </a>
//                 }
//             });

//             loadingSandboxes.map((sandboxInfo, i) => {
//                 let {avatarClasses, backgroundColor, avatarText} = this.getAvatarInfo(sandboxInfo.apiEndpointIndex);
//                 let leftAvatar = <Avatar className={avatarClasses} style={{backgroundColor}}>{avatarText}</Avatar>;
//                 let info = sandboxInfo.queuePosition
//                     ? `Your sandbox is number ${sandboxInfo.queuePosition} in the creation que...`
//                     : 'Your new sandbox is being created...';
//                 let time = `${this.state.timers[sandboxInfo.sandboxId]} sec.`;

//                 sandboxes.unshift(<a key={`new-${i}`} style={{textDecoration: 'none'}}>
//                     <ListItem button disabled>
//                         <ListItemIcon>
//                             {leftAvatar}
//                         </ListItemIcon>
//                         <ListItemText primary={<span style={{color: 'blue'}}>{sandboxInfo.name}</span>} secondary={info}/>
//                         <ListItemIcon style={{display: 'block'}}>
//                             <CircularProgress size={30} thickness={4} style={{marginLeft: '10px'}}/>
//                             <div style={{textAlign: 'center'}}>
//                                 <span style={{fontSize: '10px'}}>{time}</span>
//                             </div>
//                         </ListItemIcon>
//                     </ListItem>
//                 </a>)
//             });
//         }

//         return <Paper className='sandboxes-wrapper paper-card'>
//             <h3>
//                 <div className='sandbox-sort-wrapper'>
//                     <IconButton onClick={() => this.setState({desc: !this.state.desc})}>
//                         <Sort className={!this.state.desc ? 'rev' : ''} style={{fill: this.props.theme.p5}}/>
//                     </IconButton>
//                     <Select style={{width: '140px', marginLeft: '16px', color: 'whitesmoke', fill: 'whitesmoke'}} value={this.state.sort}
//                             className='select' onChange={e => this.setState({sort: e.target.value})}>
//                         <MenuItem value={SORT_VALUES[0].val}>
//                             {SORT_VALUES[0].label}
//                         </MenuItem>
//                         <MenuItem value={SORT_VALUES[1].val}>
//                             {SORT_VALUES[1].label}
//                         </MenuItem>
//                     </Select>
//                 </div>
//                 <Button variant='contained' id='create_sandbox_button' color='primary' className='create-sandbox-button' onClick={this.handleCreate} data-qa='create-sandbox'>
//                     New Sandbox
//                 </Button>
//             </h3>
//             <div>
//                 {!this.props.loading && sandboxes.length > 0 && <List>
//                     {sandboxes}
//                 </List>}
//                 {!this.props.isSandboxCreating && !this.props.loading && sandboxes.length === 0 && <div className='no-sandboxes-message'>
//                     <span>You do not have any sandboxes yet. Please create a sandbox to get started.</span>
//                 </div>}
//                 {this.props.loading && <div className='loader-wrapper' data-qa='sandbox-loading-loader'>
//                     <p>
//                         Loading sandboxes
//                     </p>
//                     <CircularProgress size={80} thickness={5}/>
//                 </div>}
//             </div>
//         </Paper>;
//     }

//     getAvatarInfo = (apiEndpointIndex) => {
//         let isThree = ['5', '8'].indexOf(apiEndpointIndex) === -1;
//         let isFour = apiEndpointIndex === '7' || apiEndpointIndex === '10';
//         let avatarClasses = 'sandbox-avatar';
//         let avatarText = 'STU3';
//         let backgroundColor = this.props.theme.a1;
//         if (isFour) {
//             backgroundColor = this.props.theme.p1;
//             avatarText = 'R4';
//         } else if (!isThree) {
//             backgroundColor = this.props.theme.p3;
//             avatarText = 'DSTU2';
//         }
//         return {avatarClasses, backgroundColor, avatarText};
//     };

//     sortSandboxes = () => {
//         if (this.state.sort === SORT_VALUES[1].val) {
//             return this.props.sandboxes.sort((a, b) => {
//                 let nameA = a.name.toLowerCase();
//                 let nameB = b.name.toLowerCase();
//                 let val = 0;
//                 if (nameA > nameB) {
//                     val = 1;
//                 } else if (nameA < nameB) {
//                     val = -1;
//                 }
//                 if (!this.state.desc) {
//                     val *= -1;
//                 }
//                 return val;
//             });
//         } else {
//             return this.props.sandboxes.sort((a, b) => {
//                 let timeA = (this.props.loginInfo || []).find(i => i.sandboxId === a.sandboxId) || {accessTimestamp: parseInt(a.id)};
//                 let timeB = (this.props.loginInfo || []).find(i => i.sandboxId === b.sandboxId) || {accessTimestamp: parseInt(b.id)};
//                 let val = timeA.accessTimestamp >= timeB.accessTimestamp ? -1 : 1;
//                 if (!this.state.desc) {
//                     val *= -1;
//                 }
//                 return val;
//             });
//         }
//     };

//     handleCreate = () => {
//         this.props.onToggleModal && this.props.onToggleModal();
//     };

//     selectSandbox = (row) => {
//         let sandbox = this.props.sandboxes[row];
//         localStorage.setItem('sandboxId', sandbox.sandboxId);
//         this.props.selectSandbox(sandbox);
//     };

//     fetchSandboxes() {
//         window.fhirClient && this.props.fetchSandboxes();
//     }
// }

// const mapStateToProps = state => {
//     return {
//         sandboxes: state.sandbox.sandboxes,
//         loading: state.sandbox.loading || state.sandbox.fetchingLoginInfo,
//         creatingSandbox: state.sandbox.creatingSandbox,
//         loginInfo: state.sandbox.loginInfo,
//         isSandboxCreating: state.sandbox.creatingSandbox,
//         creatingSandboxInfo: state.sandbox.creatingSandboxInfo
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return bindActionCreators({fetchSandboxes, selectSandbox, getLoginInfo}, dispatch);
// };

// export default withTheme(withRouter(connect(mapStateToProps, mapDispatchToProps)(Index)));



import React, { Component } from 'react';
import { Paper, Button, List, ListItem, Avatar, IconButton, CircularProgress, Select, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { fetchSandboxes, selectSandbox, getLoginInfo } from "../../redux/actions/action-creators"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withErrorHandler from '../../MatxLayout/SharedCompoents/Auxilary/withErrorHandler';
import { withRouter } from 'react-router-dom';
//import { ActionLock, SocialPublic, ContentSort } from "material-ui/svg-icons/index";
import { Lock, Public, Sort } from "@material-ui/icons";
//import muiThemeable from "material-ui/styles/muiThemeable";
import { withTheme } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
//import './styles.less';
//import avTimer from 'material-ui/svg-icons/av/av-timer';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import LoadCompanyProfile from '../overview/loadCompanyProfile';
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);
const SORT_VALUES = [
    { val: 'last_used', label: 'Last Used' },
    { val: 'alphabetical', label: 'Alphabetical' }
];

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sort: 'last_used',
            desc: true
        };
    }

    componentDidMount() {
        sessionStorage.clear();
    }

    componentDidUpdate(prevProps) {
        let check = !this.props.creatingSandbox && prevProps.creatingSandbox && window.fhirClient;
        check && this.props.fetchSandboxes();
    }

    render() {
        let sandboxes = null;
        let t = null;
        if (!this.props.loading) {
            let list = this.sortSandboxes();
            sandboxes = list.map((sandbox, index) => {
                let isThree = ['1', '2', '5'].indexOf(sandbox.apiEndpointIndex) === -1;
                let isFour = sandbox.apiEndpointIndex === '7';
                let avatarClasses = 'sandbox-avatar';
                let avatarText = 'STU3';
                let col = sandbox.name === sandbox.realm ? '#f3f6f9' : 'none'
                //let backgroundColor = 'blue';
                //let backgroundColor = 'darkcyan';
                if (isFour) {
                    // backgroundColor ='blue';
                    avatarText = 'R4';
                } else if (!isThree) {
                    //backgroundColor = 'blue';
                    avatarText = 'DSTU2';
                }

                let leftAvatar = <Avatar className={avatarClasses} style={{ fontSize: '14px', backgroundColor: '#0055B4' }}   >{avatarText}</Avatar>;
                let rightIcon = sandbox.allowOpenAccess
                    ? <IconButton tooltip='Open endpoint'>
                        <Public />
                    </IconButton>
                    : <IconButton tooltip='Authorization required'>
                        <Lock />
                    </IconButton>;
                let info = <LightTooltip title={
                    <div style={{ padding: '3px' }}>
                        <div style={{ padding: '4px', border: '1px solid green', backgroundColor: 'green', color: 'white', width: '48px', borderRadius: '9px' }}>active</div>
                        <br />
                        <h5> {sandbox.name}</h5>
                        <p><span style={{ color: 'rgba(52, 49, 76, 0.54)' }}>period: </span>01/01/20-01/01/24</p>
                        <p><span style={{ color: 'rgba(52, 49, 76, 0.54)' }}>product: </span>{sandbox.sandboxType !== null ? <span>{sandbox.sandboxType.sandbox_type}</span> : ''}</p>
                        <h5>Credentials</h5>
                        <p><span style={{ color: 'rgba(52, 49, 76, 0.54)' }}>License: </span>8735ijwe123jhsgdbfbff</p>
                        <h5>Hosting</h5>
                        <p><span style={{ color: 'rgba(52, 49, 76, 0.54)' }}>FHIR: </span>{avatarText}</p>
                        <p><span style={{ color: 'rgba(52, 49, 76, 0.54)' }}>Zone: </span>US-central</p>
                    </div>

                } arrow>
                    <IconButton><InfoIcon /></IconButton>
                </LightTooltip>
                return <ListItem
                    style={{ backgroundColor: col }}

                    button key={index} onClick={() => this.selectSandbox(index)}>
                    <ListItemIcon>
                        {leftAvatar}
                    </ListItemIcon>
                    <ListItemText
                        primary={<span style={{
                            color:
                                'black'
                        }}>

                            {sandbox.name}{sandbox.sandboxType !== null ? <span>({sandbox.sandboxType.sandbox_type})</span> : ''}

                        </span>} secondary={sandbox.description || 'no description available'} />
                    <ListItemIcon style={{ display: 'block' }}>
                        {/* <CircularProgress size={30} thickness={4} style={{marginLeft: '10px'}}/> */}
                        {rightIcon} &nbsp;{info}
                    </ListItemIcon>
                </ListItem>



                // <ListItem key={index} primaryText={sandbox.name}  secondaryText={sandbox.description || 'no description available'}
                //                  leftIcon={leftAvatar} rightIcon={rightIcon} onClick={() => this.selectSandbox(index)} id={sandbox.name}/>
            });

        }

        return <div>
           <div>
           <LoadCompanyProfile />
           </div>
            <Grid container spacing={2} >
                <Grid item sm={6} xs={12}>
                    {/* <div style={{padding:'4px',border:'1px solid green',backgroundColor:'green',color:'white',width:'48px',borderRadius:'9px'}}>active</div> */}
                    <h3>
                        <div >
                            <IconButton onClick={() => this.setState({ desc: !this.state.desc })}>
                                <Sort className={!this.state.desc ? 'rev' : ''} />
                            </IconButton>
                            <Select style={{ width: '140px', marginLeft: '16px', color: 'black', fill: 'whitesmoke' }} value={this.state.sort}
                                className='select' onChange={e => this.setState({ sort: e.target.value })}>
                                <MenuItem value={SORT_VALUES[0].val}>
                                    {SORT_VALUES[0].label}
                                </MenuItem>
                                <MenuItem value={SORT_VALUES[1].val}>
                                    {SORT_VALUES[1].label}
                                </MenuItem>
                            </Select>
                            {/* <SelectField style={{ width: '140px', marginLeft: '16px' }} labelStyle={{ color:'blue' }} underlineStyle={{ display: 'none' }} value={this.state.sort}
                                 className='select' onChange={(_, sort) => this.setState({ sort: SORT_VALUES[sort].val })}>
                        <MenuItem value={SORT_VALUES[0].val} primaryText={SORT_VALUES[0].label}/>
                        <MenuItem value={SORT_VALUES[1].val} primaryText={SORT_VALUES[1].label}/>
                    </SelectField> */}

                        </div>

                    </h3>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Button style={{ float: 'right', marginRight: '20px' }} variant='contained' id='create_sandbox_button' color='primary' onClick={this.handleCreate} data-qa='create-sandbox'>
                        New Healthcare Facility
                    </Button>
                </Grid>
            </Grid>
            <div>
               
                {!this.props.loading && <List>
                    {sandboxes}
                </List>}
                {this.props.loading && <div style={{ textAlign: 'center', margin: 'inherit' }}>
                    <p>
                        Loading Virtual Hospitals
                    </p>
                    <CircularProgress size={80} thickness={5} />
                </div>}
            </div>
        </div>
    }

    sortSandboxes = () => {
        if (this.state.sort === SORT_VALUES[1].val) {
            return this.props.sandboxes.sort((a, b) => {
                let nameA = a.name.toLowerCase();
                let nameB = b.name.toLowerCase();
                let val = 0;
                if (nameA > nameB) {
                    val = 1;
                } else if (nameA < nameB) {
                    val = -1;
                }
                if (!this.state.desc) {
                    val *= -1;
                }
                return val;
            });
        } else {
            return this.props.sandboxes.sort((a, b) => {
                let timeA = (this.props.loginInfo || []).find(i => i.sandboxId === a.sandboxId) || { accessTimestamp: parseInt(a.id) };
                let timeB = (this.props.loginInfo || []).find(i => i.sandboxId === b.sandboxId) || { accessTimestamp: parseInt(b.id) };
                let val = timeA.accessTimestamp >= timeB.accessTimestamp ? -1 : 1;
                if (!this.state.desc) {
                    val *= -1;
                }
                return val;
            });
        }
    };

    handleCreate = () => {
        this.props.onToggleModal && this.props.onToggleModal();
    };

    selectSandbox = (row) => {
        let sandbox = this.props.sandboxes[row];
        localStorage.setItem('sandboxId', sandbox.sandboxId);
        if (sandbox.apiEndpointIndex === '7') {
            sessionStorage.setItem('version', 'R4');
        }
        else {
            sessionStorage.setItem('version', 'STU3');
        }
        this.props.selectSandbox(sandbox);
        // console.log(sandbox)

        //this.props.startTime();
    };

    fetchSandboxes() {
        window.fhirClient && this.props.fetchSandboxes();
    }
}

const mapStateToProps = state => {
    return {
        sandboxes: state.sandbox.sandboxes,
        loading: state.sandbox.loading || state.sandbox.fetchingLoginInfo,
        creatingSandbox: state.sandbox.creatingSandbox,
        loginInfo: state.sandbox.loginInfo
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ fetchSandboxes, selectSandbox, getLoginInfo }, dispatch);
};

export default withTheme(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Index))));


