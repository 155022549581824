import * as actionTypes from "./types";
import API from '../../../lib/api'
export function pharmacyFetchStarted() {
    return {
        type: actionTypes.FETCH_PHARMACY_START
    }
}
export function pharmacyFetchSuccess() {
    return {
        type: actionTypes.FETCH_PHARMACY_SUCCESS
    }
}
export function pharmacyFetchFail() {
    return {
        type: actionTypes.FETCH_PHARMACY_FAIL
    }
}
export function pharmacyFetchlist(pharm) {
    return {
        type: actionTypes.FETCH_PHARMACY_LIST,
        payload: { pharm }
    }
}

export function loadpharmacy() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(pharmacyFetchStarted());
            let url = 'https://fhir-api-r3.collabkare.com/collabnotes/open/MedicationDispense'
            API.get(url, dispatch)
                .then(res => {
                    dispatch(pharmacyFetchlist(res));
                    dispatch(pharmacyFetchSuccess());

                })
                .catch(error => dispatch(pharmacyFetchFail()));


        }
    }
}