import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/material.css";
import {
  Grid,
  Select,
  TextField,
  Button,
  Link,
  CssBaseline,
  Paper,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./UserSignUp.css";
import db, { auth, functions } from "../../auth/firebaseconfig"; // Update with your Firebase setup
import { MatxLoading, MatxSnackbar } from "matx";
import { Blocks } from "react-loader-spinner";
import Cookies from "universal-cookie";

const CreateOrginization = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const org = urlParams.get("org");
  const user = urlParams.get("user");
  const userId = urlParams.get("userId");
  const [userInfo, setUserInfo] = useState();
  const [isValidated, setIsValidated] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  let [noDomain, setNoDomain] = useState(false);
  const cookies = new Cookies();

  useEffect(async () => {
    if (org) {
      const forbiddenDomains = ["gmail", "mail", "yahoo", "rediff"];
      if (forbiddenDomains.includes(org?.toLocaleLowerCase())) {
        noDomain = true;
        setNoDomain(noDomain);
      }
      if (userId) {
      const userRef = db.collection("Registration").doc(userId); // Corrected line
      const userDoc = await userRef.get();
      if (userDoc.exists) {
        const userData = userDoc.data();
        setIsValidated(true);
        setUserInfo(userData);
      }
      else {
        window.open(`${process.env.myAccountDefault}/login`, "_self");
      }
      } else {
        window.open(`${process.env.myAccountDefault}/login`, "_self");
      }
    }
  }, [org]);

  const formik = useFormik({
    initialValues: {
      organizationName: "",
      organizationTaxId: "",
      tax: "",
      addressLine: "",
      area: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
    },
    validationSchema: Yup.object({
      organizationTaxId: Yup.string().required(
        "Organization Tax ID is required"
      ),
      tax: Yup.string().required("Tax is required"),
      addressLine: Yup.string().required("Address Line is required"),
      area: Yup.string().required("Area is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      country: Yup.string().required("Country is required"),
      pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^\d{6}$/, "Invalid pincode (should be 6 digits)"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setShowLoader(true);
      let payload = {
        organization: noDomain ? values.organizationName : org,
        displayName: noDomain ? values.organizationName : org,
        organizationTaxId: values?.organizationTaxId,
        tax: values?.tax,
        addressLine: values?.addressLine,
        area: values?.area,
        state: values?.state,
        city: values?.city,
        country: values?.country,
        pincode: values?.pincode,
        userId: userId,
        user: user,
        email: user,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        gender: userInfo?.gender,
        password: userInfo?.password,
        phone: userInfo?.phone,
        userId: userInfo?.userId,
      };
      let tenantCreation = await createTenantandUser(payload);
      if (tenantCreation?.success) {
        let tenantinfo = tenantCreation.userRecord;
        payload = {
          ...payload,
          tenantId: tenantinfo.tenantId,
          uid: tenantinfo.uid,
        };
        cookies.set("organization",tenantinfo.tenantId)
        const createnewUser = functions.httpsCallable("createUser");
        const response = await createnewUser(payload);
        let res = response.data;
        if (res.success == true || res.message =="User already exists") {
          resetForm();
          setShowLoader(false);
          setShowSnackbar(true);
          setSnackbarMsg("Orginization created successfully.");
          setTimeout(() => {
            window.open(`${process.env.myAccountDefault}/login`, "_self");
          }, 2000);
        } else {
          setShowLoader(false);
          setShowSnackbar(true);
          setSnackbarMsg(res?.message);
        }
      }
    },
  });

  const createTenantandUser = async (data) => {
    try {
      const response = await fetch(
        "https://us-central1-collabkare-250512.cloudfunctions.net/getTenantAndUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayName: data?.displayName,
            email: data?.email,
            password: data?.password,
            phone: userInfo?.phone.replace(/[\s-]/g, ''),
            userName:userInfo?.firstName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error(error);
      return { error: error.message };
    }
  };

  return (
    <div>
      {!isValidated ? (
        <MatxLoading />
      ) : (
        <Grid container component="main" className={"root"}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={6} className={"image"} />
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            style={{ boxShadow: "none" }}
          >
            <div className={"paper"}>
              {showLoader ? (
                <Blocks
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper loading"
                  visible={true}
                />
              ) : (
                <div>
                  <div className={"paper1"}>
                    <img
                      src="/assets/images/Asset-9.svg"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "300px",
                      }}
                    ></img>
                  </div>
                  <h5>
                    <div className="h4Text">Create your organization</div>
                  </h5>
                  <form className={"form"} onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        {noDomain ? (
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="organization Name"
                            size="small"
                            id="organizationName"
                            name="organizationName"
                            error={
                              formik.touched.organizationName &&
                              Boolean(formik.errors.organizationName)
                            }
                            helperText={
                              formik.touched.organizationName &&
                              formik.errors.organizationName
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.organizationName}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="organization Name"
                            size="small"
                            id="organizationName"
                            name="organizationName"
                            onBlur={formik.handleBlur}
                            value={org}
                            disabled={true}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Organization Tax Id"
                          name="organizationTaxId"
                          placeholder="Your organization Tax Id"
                          size="small"
                          error={
                            formik.touched.organizationTaxId &&
                            Boolean(formik.errors.organizationTaxId)
                          }
                          helperText={
                            formik.touched.organizationTaxId &&
                            formik.errors.organizationTaxId
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.organizationTaxId}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Tax Number"
                          name="tax"
                          placeholder="Your tax number"
                          size="small"
                          error={
                            formik.touched.tax && Boolean(formik.errors.tax)
                          }
                          helperText={formik.touched.tax && formik.errors.tax}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.tax}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="addressLine"
                          name="addressLine"
                          placeholder="Your Address Line"
                          size="small"
                          error={
                            formik.touched.addressLine &&
                            Boolean(formik.errors.addressLine)
                          }
                          helperText={
                            formik.touched.addressLine &&
                            formik.errors.addressLine
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addressLine}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Area"
                          name="area"
                          placeholder="Area"
                          size="small"
                          error={
                            formik.touched.area && Boolean(formik.errors.area)
                          }
                          helperText={formik.touched.area && formik.errors.area}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.area}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="State"
                          name="state"
                          placeholder="state"
                          size="small"
                          error={
                            formik.touched.state && Boolean(formik.errors.state)
                          }
                          helperText={
                            formik.touched.state && formik.errors.state
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="City"
                          name="city"
                          placeholder="City"
                          size="small"
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Country"
                          name="country"
                          placeholder="country"
                          size="small"
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country && formik.errors.country
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.country}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Pincode"
                          name="pincode"
                          placeholder="pincode"
                          size="small"
                          error={
                            formik.touched.pincode &&
                            Boolean(formik.errors.pincode)
                          }
                          helperText={
                            formik.touched.pincode && formik.errors.pincode
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pincode}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent={"flex-end"}
                          sx={{ marginTop: "15px" }}
                        >
                          <div>
                            <Button variant="contained" type="submit">
                              Submit
                            </Button>
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              )}
            </div>
            <MatxSnackbar
              open={showSnackbar}
              setOpen={setShowSnackbar}
              message={snackbarMsg}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CreateOrginization;
