import React from "react";

const mapRoutes = [
  {
    path: "/virtualhospitals",
    component: React.lazy(() => import("./VirtualHospital"))
  },
  {
    path: "/applications",
    component: React.lazy(() => import("../Applications"))
  },
  {
    path: "/video",
    component: React.lazy(() => import("../video"))
  },
  
];

export default mapRoutes;
