import * as types from "./types";
import API from "../../../lib/api"
import Cookies from 'universal-cookie';

export function resetState() {
    return { type: types.APP_RESET_STATE }
}

export function app_setScreen(screen) {
    return { type: types.SET_APP_SCREEN, payload: screen }
}

export function setTermsLoading(loading) {
    return { type: types.SET_TERMS_LOADING, payload: { loading } }
}

export function setTerms(terms) {
    return { type: types.SET_TERMS, payload: { terms } }
}

export function resetGlobalError() {
    return {
        type: types.SET_ERROR_TO_SHOW,
        payload: { error: undefined }
    }
}

export function setGlobalError(error) {
    return dispatch => {
        dispatch({
            type: types.SET_ERROR_TO_SHOW,
            payload: { error }
        });
    }
}

export function loadTerms() {
    return (dispatch, getState) => {
        let state = getState();
        dispatch(setTermsLoading(true));
        let url = `${process.env.sandboxManagerApiUrl}/termsofuse`;
        //let url = `${state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl}/termsofuse`;
        API.get(url, dispatch)
            .then(terms => dispatch(setTerms(terms)))
            .catch(() => dispatch(setTermsLoading(false)));
    }
}

export function signOut() {
    return (dispatch, getState) => {
        let state = getState();
        sessionStorage.clear();
        // localStorage.clear();
        const cookies = new Cookies();
        cookies.remove('iPlanetDirectoryPro');
        cookies.remove('id');
        let domain = cookies.get('domain');
        let managerLogoutdomainUrl = process.env.managerLogoutdomainUrl;
        //'https://auth.collabkare.com/am/oauth2/';
        let domainurl = `${managerLogoutdomainUrl}${domain}/connect/endSession`;
        let managerLogoutUrl = process.env.managerLogoutUrl;
        //"https://auth.collabkare.com/am/oauth2/collabnotes/connect/endSession";
        dispatch(resetState());
        // window.location.href = `${configuration.oauthLogoutUrl}?hspcRedirectUrl=${window.location.origin}`;


        window.location.href = `${domain === undefined ? managerLogoutUrl : domainurl}?id_token_hint=${localStorage.fhirauthclient}&post_logout_redirect_uri=${process.env.redirectUri}/login`;
        // window.location.href='https://myaccount.collabkare.com/login';

        //recent changes  uaa signout
        //  window.location.href = `${configuration.oauthLogoutUrl}?redirect=${window.location.origin}&client_id=sand_man`;
        //  window.location.href = `${configuration.managerLogoutUrl}?id_token_hint=${localStorage.fhirauthclient}&post_logout_redirect_uri=https://myaccount.collabkare.com/login`;
    }
}
