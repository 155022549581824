import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import React from "react";
import ReactDOM from "react-dom";
import "./_index.scss";
import routes from "./app/RootRoutes"
import * as serviceWorker from "./serviceWorker";
import App from "./app/App";
import configStore from "./app/redux/reducers/RootReducer"
import { Provider } from "react-redux";
import AppContext from "./app/appContext"
// cssVars();
configStore()
    .then(store => {
        let provider = <AppContext.Provider value={{ routes }}> <Provider store={store}>
            <App />
        </Provider></AppContext.Provider>;
        const rootNode = document.getElementById('root');
        // ReactDOM.render(<App />, rootNode);
        ReactDOM.render(provider, rootNode);
    });
//ReactDOM.render(<App />, document.getElementById("root"));

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();




