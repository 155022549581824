import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles(theme => ({
    loading: {
        position: "fixed",
        left: 0,
        right: 0,
        top: "calc(50% - 20px)",
        margin: "auto",
        height: "40px",
        width: "40px",
        "& img": {
            position: "absolute",
            height: "25px",
            width: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto"
        }
    },
    cont: {
        position: "fixed",
        left: 0,
        right: 0,
        top: "calc(60% - 20px)",
        margin: "auto",
        textAlign: 'center'
    }
}));

const Load = props => {
    const classes = useStyles();
    const handle = () => {
        if (localStorage.getItem('oauthUser') !== null) {
            props.history.push('/home');
        } else {
            props.history.push('/');
        }
    }
    return (
        <div>
            <div className={classes.loading}>
                <img src="/assets/images/Asset-10.svg" alt="" />
                <CircularProgress />

            </div>
            <span className={classes.cont}>Welcome to the CollabKare Virtual Hospital,<a href='' onClick={handle}>click here</a> if you're not redirected to the login screen.</span>
        </div>
    );
};

export default withRouter(Load);
