// src/components/DemoFormSection.js
import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';

const DemoFormSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.name || !formData.email || !formData.company || !formData.message) {
      setSnackbarMessage('Please fill in all fields.');
      setSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    // Handle form submission logic (you can send it to an API)
    console.log('Form submitted:', formData);
    setSnackbarMessage('Demo request submitted successfully!');
    setSeverity('success');
    setOpenSnackbar(true);

    // Reset form
    setFormData({ name: '', email: '', company: '', message: '' });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Grid container spacing={2}>
      {/* Left Side: Image */}
      <Grid item xs={12} sm={6}>
        <Paper
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <img 
            src="https://via.placeholder.com/600x400" // Replace with your image URL
            alt="Demo"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Maintain aspect ratio
          />
        </Paper>
      </Grid>

      {/* Right Side: Demo Request Form */}
      <Grid item xs={12} sm={6}>
        <Paper
          style={{
            height: '100%',
            padding: '2rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Ensure equal height with the image
          }}
        >
          <Typography variant="h4" gutterBottom>
            Request a Demo
          </Typography>
          <form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              label="Company"
              variant="outlined"
              fullWidth
              margin="normal"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              margin="normal"
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
            />
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Submit
            </Button>
          </form>

          {/* Snackbar for feedback */}
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DemoFormSection;
