import React from "react";

const DataLakeRoutes = [
  {
    path: "/:sandboxId/datalake",
    component: React.lazy(() => import("./datalake"))
  },
  
];

export default DataLakeRoutes;