export default {
    loading: false,
    // resourceResults: {}
     data:[],
     subload:false,
     sub:[],
     error:undefined,
    //  creating:false,
    //  notification:[],
    //  err:undefined
     
}

//old code
// export default {
//     loading: false,
//     // resourceResults: {}
//      data:[]
// }