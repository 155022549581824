import React from "react";

const PersonaRoutes = [
  {
    path: "/:sandboxId/patients",
    
    component: React.lazy(() => import("./persona"))
  },

  {
    path: "/:sandboxId/patient/:id",
    
    component: React.lazy(() => import("./PatientForm"))
  },
  {
    path: "/:sandboxId/practitioners",
    
    component: React.lazy(() => import("./persona"))
  },
  {
    path: "/:sandboxId/personas",
    
    component: React.lazy(() => import("./persona"))
  },
  {
    path: "/:sandboxId/ehr",
    
    component: React.lazy(() => import("../../Platforms"))
  },

];



export default PersonaRoutes;