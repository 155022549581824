import * as actionTypes from "./types";
import API from '../../../lib/api'
export function providerDirFetchStarted() {
    return {
        type: actionTypes.FETCH_PROVIDER_DIR_START
    }
}
export function providerDirFetchSuccess() {
    return {
        type: actionTypes.FETCH_PROVIDER_DIR_SUCCESS
    }
}

export function providerDirFetchError(error) {
    return {
        type: actionTypes.FETCH_PROVIDER_DIR_FAIL,
        payload: { error }
    }
}
export function providerDirFetch(practitioner) {
    return {
        type: actionTypes.FETCH_PROVIDER_DIR,
        payload: { practitioner }
    }
}


export function providerdirectory() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(providerDirFetchStarted());
            //let url = `${localStorage.fhirserverurl}/${sessionStorage.version == 'STU3' ? 'collabkare9' : 'collabkare10'}/open/Practitioner`
            let url = `${sessionStorage.version == 'STU3' ? process.env.baseServiceUrl_8 : process.env.baseServiceUrl_9}`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(providerDirFetchSuccess());
                    dispatch(providerDirFetch(res));

                })
                .catch(error => dispatch(providerDirFetchError(error)));


        }
    }
}