import React from "react";

const FhirMonitoringRoutes = [
  {
    path: "/:sandboxId/metrics",
    component: React.lazy(() => import("./fhirmonitoring"))
  },
  {
    path: "/:sandboxId/auditlogs",
    component: React.lazy(() => import("./Auditlogs"))
  },
  
];

export default FhirMonitoringRoutes;