import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default function (state = initialState, action) {
    state = Object.assign({}, state);
    switch (action.type) {
        case types.FETCH_PHARMACY_START:
            state.pharmload = true;
            state.pharm = [];
            break;
        case types.FETCH_PHARMACY_SUCCESS:
            state.pharmload = false;
            break;
        case types.FETCH_PHARMACY_FAIL:
            state.pharmload = false;
           // state.fetchingError = action.payload.error;
            break;
        case types.FETCH_PHARMACY_LIST:
            state.pharm = action.payload.pharm;
            break;
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.pharmacy: state;
            //state.pharmload = false;
        
            break;
    }
    return state;
}
