import React from "react";

const decissionRoutes = [
  {
    path: "/:sandboxId/cdshooks",
    component: React.lazy(() => import("./CdsHooks"))
  },
  
];

export default decissionRoutes;