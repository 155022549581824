import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getMuiTheme, MuiThemeProvider } from "material-ui/styles";
// import FeedbackIcon from 'material-ui/svg-icons/action/feedback';
import FeedbackIcon from "@material-ui/icons/Feedback";
import CloseIcon from "@material-ui/icons/Close";
//import * as glib from 'virtual-manager-lib/utils/';
import * as lib from "../../lib";
import * as actionCreators from "../../redux/actions/action-creators";
//import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import * as React from "react";
import * as PropTypes from "prop-types";
import Layout from "../SharedCompoents/layout";
import CreateSandbox from "../../views/Virtualhospital/Createsandbox";
import Init from "./init";
import FHIR from "fhirclient";
import { CircularProgress, Dialog } from "@material-ui/core";
import Snackbar from "./snackbar";
import { Button, Popover, DialogTitle, DialogContent } from "@material-ui/core";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import SentimentDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentDissatisfiedOutlined";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === "" && (valid = false);
  });

  return valid;
};
class App extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.location.pathname !== "/launchApp") {
      if (this.props.fhir.smart.data.server) {
        let smart = window.FHIR.client(this.props.fhir.smart.data.server);
        let split = smart.server.serviceUrl.split("/");
        let isCorrectServer = split
          ? split.indexOf(sessionStorage.sandboxId) >= 0
          : true;
        if (
          sessionStorage.sandboxId &&
          !isCorrectServer &&
          this.props.history.location.search.indexOf("?code=") === -1
        ) {
          let newSmart = Object.assign({}, smart);
          window.fhirClient = smart;
          newSmart.server.serviceUrl = smart.server.serviceUrl.replace(
            split[3],
            sessionStorage.sandboxId
          );
          this.props.selectSandbox(
            this.props.sandbox.sandboxes.find(
              (i) => i.sandboxId === sessionStorage.sandboxId
            )
          );
        }
      }
    }

    this.state = {
      open: false,
      name: "",
      email: "",
      comments: "",
      organisationname: "",
      disable: true,
      anchorEl: null,

      formErrors: {
        name: "",
        email: "",
        comments: "",
        organisationname: "",
      },
    };
  }
  submit = (event) => {
    event.preventDefault();

    if (formValid(this.state)) {
      console.log(this.state);

      this.setState({
        name: "",
        email: "",
        comments: "",
        organisationname: "",
      });
    } else {
      console.error("FORM IS INVALID");
    }
  };

  componentDidUpdate() {
    this.setSandboxId();
  }

  componentDidMount() {
    this.setSandboxId();
    window.addEventListener("resize", this.onResize);
    // (function (d, t) {
    //     var BASE_URL = "https://chat.collabkare.com/";
    //     var g = d.createElement(t),
    //       s = d.getElementsByTagName(t)[0];
    //     g.src = BASE_URL + "/packs/js/sdk.js";
    //     s.parentNode.insertBefore(g, s);
    //     g.onload = function () {
    //       window.chatwootSDK.run({
    //         websiteToken: "B5JpNrdcFEzpKyDYTC8JmJ66",
    //         baseUrl: BASE_URL
    //       });
    //     };
    //   })(document, "script");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
      name: "",
      email: "",
      comments: "",
      organisationname: "",
      //disable: true,
      formErrors: {
        name: "",
        email: "",
        comments: "",
        organisationname: "",
      },
    });
  };
  handleChange = (e) => {
    e.preventDefault();

    const emailRegex = RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
   
      case "organisationname":
        formErrors.organisationname =
          value.length < 3 ? "minimum 3 characaters required" : "";
        break;

      default:
        break;
    }

    this.setState({
      formErrors,
      [name]: value,
    });
  };
  handleNewUserMessage = (newMessage) => {
    console.log(`New message incomig! ${newMessage}`);
    // Now send the message throught the backend API
  };
  handleClicks = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: !this.state.open });
  };

  handleCloses = () => {
    this.setState({ open: false });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : null;

    const { formErrors } = this.state;

    let showLoader =
      this.props.selecting || this.props.resetting || this.props.deleting;
    let loaderText = this.props.deleting
      ? "Deleting Virtual Hospital"
      : this.props.resetting
      ? "Resetting Virtual Hospital data"
      : "Loading Virtual Hospital data";
    let theme = getMuiTheme(this.props.ui.theme);

    return (
      <div>
        <Init {...this.props} />
        <Layout
          path={this.props.history.location.pathname}
          selectSandbox={this.props.selectSandbox}
          onAuthInit={this.props.init}
          settings={this.props.config.xsettings.data}
          signOut={this.props.signOut}
          updateSandboxInvite={this.props.updateSandboxInvite}
          CreateSandbox={CreateSandbox}
          hideNotification={this.props.hideNotification}
          markAllNotificationsSeen={this.props.markAllNotificationsSeen}
        >
          {!this.props.selecting &&
            this.props.config.xsettings.status === "ready" && (
              <div className="app-root" ref={this.refStage()}>
                <div
                  className="stage"
                  style={{ marginBottom: this.props.ui.footerHeight }}
                >
                  {!this.getCheck() && this.props.children}
                </div>
              </div>
            )}
          {/* {!showLoader && this.props.location.pathname !== "/" && (
            <div
              className="feedback-button"
              style={{ backgrounColor: "#0055B4" }}
            >
              <Button
                onClick={this.handleClicks}
                variant="outlined"
                color="primary"
                
                endIcon={<FeedbackIcon />}
              >
                Submit Feedback
                <span
                  aria-owns={open ? "simple-popover" : null}
                  aria-haspopup="true"
                
                />
              </Button>
              <Popover
                //id={id}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ marginTop: "-55px" }}
              >
                <DialogTitle
                  display="inline"
                  id="title"
                  style={{ fontSize: "25px" }}
                >
                  Send us feedback
                  <CloseIcon
                    onClick={this.handleCloses}
                    style={{ float: "right", color: "black" }}
                  />{" "}
                </DialogTitle>

                <DialogContent>
                  <p style={{ fontSize: "15px", color: "black" }}>
                    Thank you for taking the time to give us feedback
                  </p>

                  <div
                    style={{
                      backgroundColor: "rgb(230, 243, 255)",
                      padding: "1px",
                    }}
                  >
                    <p style={{ fontSize: "15px", color: "black" }}>
                      <i
                        className="fa fa-info-circle"
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          color: "#1276d5",
                          fontSize: "17px",
                        }}
                        aria-hidden="true"
                      ></i>
                      if you need help, please contact support
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <p style={{ color: "red", marginTop: "17px" }}>*</p>
                    </div>
                    <div>
                      <p style={{ fontSize: "15px", color: "black" }}>
                        Are you satisfied with your experience?
                      </p>
                    </div>
                  </div>

                  <SentimentSatisfiedOutlinedIcon
                    fontSize="large"
                    style={{ color: "black" }}
                  />
                  <SentimentDissatisfiedOutlinedIcon
                    fontSize="large"
                    style={{ color: "black" }}
                  />
                  <br />
                  <br />
                  <form onSubmit={this.submit} noValidate>
                    
                    <TextareaAutosize
                      style={{
                        height: "120px",
                        width: "350px",
                        resize: "vertical",
                      }}
                      // rowsMin={5}
                      autoFocus
                      margin="dense"
                      id="comment"
                      required
                      minRows={5}
                      placeholder="Tell us about your experience?"
                      name="comments"
                      noValidate
                      value={this.state.comments}
                      onChange={this.handleChange}
                    />
                    {formErrors.comments.length > 0 && (
                      <span className="errorMessage">
                        {formErrors.comments}
                      </span>
                    )}

                    <br />
                    <br />
                    <br />
                   
                    <Button
                      type="submit"
                      variant="outlined"
                      color="secondary"
                     
                      style={{ color: "#0055B4" }}
                    >
                      Submit feedback.
                    </Button>
                    <br />
                    <br />
                  
                  </form>
                </DialogContent>
              </Popover>
            </div>
          )} */}

          {showLoader && (
            <Dialog
              classes={{ paper: "full-loader-wrapper" }}
              open={showLoader}
            >
              <p style={{ color: "black" }}>{loaderText}</p>
              <CircularProgress
                size={50}
                thickness={5}
                //style={{color:'#0055B4'}}
              />
            </Dialog>
          )}
          {!!this.props.errorToShow && (
            <Snackbar
              message={this.props.errorToShow}
              theme={theme}
              onClose={() => this.props.resetGlobalError()}
            />
          )}
        </Layout>
      </div>
    );
  }

  setSandboxId = () => {
    let check = this.getCheck();
    check &&
      (sessionStorage.sandboxId = window.location.pathname.split("/")[1]);
    check &&
      localStorage.setItem("sandboxId", window.location.pathname.split("/")[1]);
    check && sessionStorage.sandboxId && this.forceUpdate();
  };

  getCheck = () => {
    return (
      !sessionStorage.sandboxId &&
      window.location.pathname.split("/")[1] &&
      window.location.pathname.split("/")[1] !== "virtualhospitals" &&
      window.location.pathname.split("/").length >= 2
    );
  };

  // Event handlers ----------------------------------------------------------
  onResize = () => this.forceUpdate();

  // Refs --------------------------------------------------------------------
  refStage = () => (el) => {
    if (el && this.props.ui.clientWidth !== el.clientWidth) {
      this.props.ui_SetClientWidth(el.clientWidth);
    }
  };
}

App.propTypes = {
  fhir: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
    ...lib,
    selecting: state.sandbox.selecting,
    resetting: state.sandbox.resetting,
    deleting: state.sandbox.deleting,
    errorToShow: state.app.errorToShow,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actionCreators }, dispatch);

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedComponent };
export default withRouter(connectedComponent);
