import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import 'firebase/compat/auth';

// var firebaseConfig = {
//     apiKey: 'AIzaSyB-WNzDSLcurwmX_GKPJE5BwPyk-DL4MMg',
//     authDomain: 'smartonfhir-274312.firebaseapp.com',
//     databaseURL: 'https://smartonfhir-274312.firebaseio.com',
//     projectId: 'smartonfhir-274312',
//     storageBucket: 'smartonfhir-274312.appspot.com',
//     messagingSenderId: '1057419632244',
//     appId: '1:1057419632244:web:20e4c78ebee47a01793b21',
// }

const firebaseConfig = {
    apiKey: 'AIzaSyBmzPJF-LdrKzba3u4pt5PLLMpqOn-SpH0',
    authDomain: 'collabkare-250512.firebaseapp.com',
    databaseURL: 'https://collabkare-250512.firebaseio.com',
    projectId: 'collabkare-250512',
    storageBucket: 'collabkare-250512.appspot.com',
    messagingSenderId: '871290887238',
    appId: '1:871290887238:web:28402f0a92f723e78d3e47',
    measurementId: 'G-MZ4C0J5VET',
}
firebase.initializeApp(firebaseConfig)

// Access Firebase services using the imported modules
const auth = firebase.auth(); // Initialize Firebase Auth

// Export the auth instance
export { auth };
const db = firebase.firestore()
// export default db
export default db
export const storage = firebase.storage()
export const functions = firebase.functions()