import React, { Component } from 'react';
import AvailableSandboxes from './Avaliablesandboxes';
import CreateSandbox from './Createsandbox';
//import withErrorHandler from 'virtual-manager-lib/hoc/withErrorHandler';
import { app_setScreen, fetchSandboxes, loadTerms, loadInvites, fetchUserNotifications } from '../../redux/actions/action-creators'
//'../../../redux/action-creators';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Breadcrumb } from "matx";
import Layout1Topbar from '../../MatxLayout/Layout1/Layout1Topbar';
//import './styles.less';
//import Footer from "virtual-manager-lib/components/Navigation/Footer";
//import Page from 'virtual-manager-lib/components/Page';


class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    componentDidMount () {
        this.props.app_setScreen('virtualhospitals');
      this.props.fetchSandboxes();
      
    }

    render() {
       // let t=localStorage.getItem('timeStamp');
        let dialog = this.state.open
            ? <CreateSandbox onCancel={this.toggle} open={this.state.open}/>
            : null;

        return <div className="m-sm-30">
        <div  className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: "List Of virtual CIN", path: "#" },
              { name: "virtual Clinically Integrated Network" }
            ]}
          />
        </div>
        {/* <Card className="px-6 pt-2 pb-4"><SimpleForm /></Card> */}
           
    <div>
        {dialog}

        <div >
            <AvailableSandboxes onToggleModal={this.toggle}>

            </AvailableSandboxes>
            
            {/* <h2 className='t'>{t}</h2> */}
            {/* <Timer/> */}
        </div>
        {/* <Footer loadTerms={this.props.loadTerms} terms={this.props.terms}/> */}
    </div></div>;


    }

    toggle = () => {
        this.setState({ open: !this.state.open });
    }
}

const mapStateToProps = state => {
    return {
        //terms: state.app.terms
    }
};
const mapDispatchToProps = dispatch => bindActionCreators({ app_setScreen, fetchSandboxes, loadTerms, loadInvites, fetchUserNotifications }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);




