import { authRoles } from "./auth/authRoles"
//"../../auth/authRoles";
let sandbox = sessionStorage.getItem("id");
let sandboxId = sandbox !== "undefined" ? JSON.parse(sandbox) : null

let path = window.location.pathname
// const mapStateToProps = state => {
//   return {
//     sandbox: state.sandbox.sandboxes.find(i => i.sandboxId === sessionStorage.sandboxId),
//     sandboxes: state.sandbox.sandboxes,
//   }
// };

// export default navigations;
export const navigations = path.startsWith(sandboxId !== null && '/' + sandboxId.sandboxId) && path !== "/virtualhospitals" ? [

  {
    name: "Summary",
    path: '/' + sandboxId.sandboxId + "/overview",
    icon: "description_outlined",

  },

  {
    name: "FHIR Facility",
    icon: "queue",
    children: [
      {
        name: "Hospital",
        path: '/' + sandboxId.sandboxId + "/hospital",
        iconText: "F"
      },
      {
        name: "Users",
        iconText: "US",
        path: '/' + sandboxId.sandboxId + '/FaciltiesUsers',

      },
      // {
      //   name: "Organisation Map",
      //   path: '/' + sandboxId.sandboxId + "/organisationmap",
      //   iconText: "A"
      // },
      {
        name: "Practice",
        path: '/' + sandboxId.sandboxId + "/practice",
        iconText: "HS"
      },

      // {
      //   name: "Teams",
      //   path: '/' + sandboxId.sandboxId + "/teams",
      //   iconText: "TS"
      // },
      {
        name: "Practitioners",
        iconText: "PR",
        path: '/' + sandboxId.sandboxId + '/practitioners',

      },
      {
        name: "Patients",
        iconText: "PA",
        path: '/' + sandboxId.sandboxId + '/patients',

      },



      {
        name: "personas",
        iconText: "PS",
        path: '/' + sandboxId.sandboxId + '/personas',

      },
    ]
  },

  // {
  //   name: "FHIR Monitoring",
  //   icon: "computer",
  //   children: [
  //     {
  //       name: "Performance",
  //       path: '/' + sandboxId.sandboxId + "/metrics",
  //       iconText: "F"
  //     },
  //     {
  //       name: "Audit Logs",
  //       path: '/' + sandboxId.sandboxId + "/auditlogs",
  //       iconText: "A"
  //     },

  //   ]
  // },

  {
    name: "FHIR Access Control",
    icon: "grain",
    children: [
      {
        name: "Consents",
        path: '/' + sandboxId.sandboxId + "/consents",
        iconText: "F"
      },
    ]
  },


  {
    name: "FHIR Decision",
    icon: "filter_none",
    children: [
      {
        name: "CDS Hooks",
        path: '/' + sandboxId.sandboxId + '/cdshooks',
        iconText: "C"
      },
    ]
  },
  {
    name: "FHIR Quality Metris",
    icon: "grain",
    children: [
      {
        name: "FHIR eCQMS",
        path: '/' + sandboxId.sandboxId + "/fhirecqms",
        iconText: "F"
      },
    ]
  },
  {
    name: "FHIR Server config",
    icon: "whatshot",
    children: [
      {
        name: "FHIR Server Info",
        path: '/' + sandboxId.sandboxId + "/fhirInfo",
        iconText: "F"
      },
      {
        name: "FHIR Features",
        path: '/' + sandboxId.sandboxId + "/fhirfeatures",
        iconText: "F"
      },
      {
        name: "FHIR APIs",
        path: '/' + sandboxId.sandboxId + "/fhirapi",
        iconText: "E"
      },
      {
        name: "Document Server",
        path: '/' + sandboxId.sandboxId + "/documentserver",
        iconText: "E"
      },
      // {
      //   name: "Terminology",
      //   path: '/' + sandboxId.sandboxId + "/terminology",
      //   iconText: "T"
      // },

      {
        name: "Notifications",
        path: '/' + sandboxId.sandboxId + "/notifications",
        iconText: "N"
      },
      {
        name: "Data Manager",
        path: '/' + sandboxId.sandboxId + "/datamanager",
        iconText: "D"
      },

    ]
  },

  {
    name: "FHIR Impl Guides",
    icon: "grain",
    children: [
      {
        name: "FHIR Profiles",
        path: '/' + sandboxId.sandboxId + "/profiles",
        iconText: "F"
      },
      // {
      //   name: "IG",
      //   path: '/' + sandboxId.sandboxId + "/igs",
      //   iconText: "F"
      // },
    ]
  },
  {
    name: "SMART on FHIR Apps",
    path: '/' + sandboxId.sandboxId + "/apps",
    icon: "apps"
  },
  // {
  //   name: "Data Lake",
  //   path: '/' + sandboxId.sandboxId + "/datalake",
  //   icon: "ac_unit_outlined"
  // },

  // {
  //   name: "Settings",
  //   path: '/' + sandboxId.sandboxId + "/settings",
  //   icon: "settings"
  // },
  // {
  //   name: "SMART on FHIR Apps",
  //   icon: "grain",
  //   children: [
  //     {
  //       name: "Apps",
  //       icon: "apps",
  //       path: '/' + sandboxId.sandboxId + '/apps',

  //     },
  //     {
  //       name: "Launch Scenarious",
  //       icon: "launch",
  //       path: '/' + sandboxId.sandboxId + '/launch',

  //     },


  // {
  //   name: "ehr",
  //   icon: "launch",
  //   path: '/' + sandboxId.sandboxId + '/ehr',

  // },
  // ]
  //},


] : [
  // {
  //   name: "Dashboard",
  //   icon: "airplay",
  //   children: [

  //     {
  //       name: "Performance",
  //       path: "/performance",
  //       iconText: "P"
  //     },
  //     {
  //       name: "Compliance",
  //       path: "/security",
  //       iconText: "C"
  //     },
  //     {
  //       name: "AuditLogs",
  //       path: "/auditlogs",
  //       iconText: "A"
  //     },

  //     // {
  //     //   name: "Logs(ATNA)",
  //     //   path: "/logs",
  //     //   iconText: "L"
  //     // }
  //   ]
  // },

  // {
  //     name: "Value Based System",
  //     icon: "system_update",
  //     auth:authRoles.admin,
  //     children: [
  //       {
  //         name: "Population Health",
  //         path: "/pop-health",
  //         iconText: "B"
  //       },

  //     ]
  //   },

  {
    name: "Virtual CIN",
    path: "/virtualhospitals",
    icon: "local_hospital_outlined",
    //auth: authRoles.admin
  },
  {
    name: "Marketplace",
    path: "/mkt",
    icon: "shopping_cart",
    // auth: authRoles.admin
  },
  // {
  //   name: "AI on FHIR",
  //   path: "/ai",
  //   icon: "ac_unit",
  //   // auth: authRoles.admin
  // },
 
  // {
  //   name: "Decision Trees on FHIR",
  //   path:"/ml-tool",
  //   icon: "description",
    // children: [

    //   {
    //     name: "Decision Trees on FHIR",
    //     path: "/ml-tool",
    //     iconText: "P"
    //   },
    

    // ]
  //},
  // {
  //   name: "Pop Health on FHIR",
  //   path: "/pop-health",
  //   icon: "system_update"
  // },
  {
    name: "Applications",
    path: "/applications",
    icon: "apps"
  },
  // {
  //   name: "FHIR Inteligence",
  //   path: "/fhirinteligence",
  //   icon: "storage_icon"
  // },

  { headline: 'Services' },
  { div: 'mahesh' },
  // {
  //   name: "Home",
  //   path: "/home",
  //   icon: "dashboard",
  //   auth:authRoles.admin
  // },


  // {
  //   name: "Analytics on FHIR",
  //   path: "/pro-analytics",
  //   icon: "location_city"
  // },

  // {
  //   name: "Company",
  //   path: "/companyprofile",
  //   icon: "business",
  // },

  // {
  //   name: "Dashboard",
  //   path: '/' + sandboxId + "/dashboard/analytics",
  //   icon: "dashboard"
  // },

  // {
  //   name: "Value Based System",
  //   icon: "system_update",
  //   auth:authRoles.admin,
  //   children: [
  //     {
  //       name: "Population Health",
  //       path: "/pop-health",
  //       iconText: "B"
  //     },

  //   ]
  // },
  // {
  //   name: "Business Inteligence",
  //   icon: "location_city",
  //   children: [
  //     {
  //       name: "Provider Analytics",
  //       path: "pro-analytics",
  //       iconText: "B"
  //     },
  //     {
  //       name: "Billing Analytics",
  //       path: "bil-analytics",
  //       iconText: "P"
  //     },
  //   ]
  // },
  {
    name: "FHIR Directory",
    path: "/directory",
    icon: "assignment_ind"
  },
  // {
  //   name: "FHIR Directory",
  //   icon: "assignment_ind",
  //   children: [
  //     {
  //       name: "Insurance",
  //       path: "/insurance",
  //       iconText: "I"
  //     },
  //     {
  //       name: "Provider Directory",
  //       path: "/directory",
  //       iconText: "D"
  //     },
  //     {
  //       name: "Pharmacy",
  //       path: "/pharmacy",
  //       iconText: "P"
  //     },

  //     {
  //       name: "Laboratory",
  //       path: "/laboratory",
  //       iconText: "L"
  //     }
  //   ]
  // },
  {
    name: "FHIR Lake",
    path: "/fhirlake",
    icon: "fireplace"
  },
  {
    name: "FHIR Exchange",
    path: "/fhirdata",
    icon: "data_usage"
  },
  {
    name: "FHIR Connect",
    path: "/fhirconnect",
    icon: "device_hub"
  },
  // {
  //   name: "",
  //   icon: "",
  //   children: [
  //     // {
  //     //   name: "HIE",
  //     //   path: "/empi",
  //     //   iconText: "E"
  //     // },
  //     {
  //       name: "FHIR Ingestion",
  //       path: "/fhirpdex",
  //       iconText: "FP"
  //     },


  //     // {
  //     //   name: "FHIR Data Exchange",
  //     //   path: "/fhirdata",
  //     //   iconText: "F"
  //     // },
  //     // {
  //     //   name: "Logs(ATNA)",
  //     //   path: "/logs",
  //     //   iconText: "L"
  //     // }
  //   ]
  // },
  {
    name: "Terminology",
    path: "/terminology",
    icon: "pageview"
  },
  {
    name: "FHIR Documents",
    path: "/documentservice",
    icon: "description"
  },
  // {
  //   name: "Document Service",
  //   icon: "description",
  //   children: [
  //     {
  //       name: "FHIR Documents",
  //       path: "/documentservice",
  //       iconText: "D"
  //     },
  //   ]
  // },
  {
    name: "User Directory",
    path: "/users",
    icon: "description"
  },
  // {
  //   name: "User Directory",
  //   icon: "library_books",
  //   children: [

  //     {
  //       name: "User",
  //       path: "/user",
  //       iconText: "U"
  //     },
  //     // {
  //     //   name: "Roles",
  //     //   path: "/roles",
  //     //   iconText: "R"
  //     // },
  //     // {
  //     //   name: "Sync",
  //     //   path: "/sync",
  //     //   iconText: "S"
  //     // },
  //     {
  //       name: "Company",
  //       path: "/companyprofile",
  //       iconText: "C"
  //     },
  //     {
  //       name: "Policies",
  //       path: "/policies",
  //       iconText: "P"
  //     },
  //     // {
  //     //   name: "Company",
  //     //   path: "/companyprofile",
  //     //   icon: "business",
  //     // },
  //     // {
  //     //   name: "Groups",
  //     //   path: "/groups",
  //     //   iconText: "G"
  //     // },

  //   ]
  // },
  // {
  //   name: "AI Studio",
  //   icon: "description",
  //   children: [
  //     {
  //       name: "ML Tool",
  //       path: "/ml-tool",
  //       iconText: "B"
  //     },

  //   ]
  // },
  {
    name: "Collaborative Tools",
    path: "/chat",
    icon: "settings_applications",
    // children: [
    //   {
    //     name: "Calendar",
    //     path: "/calendar",
    //     iconText: "B"
    //   },
    //   {
    //     name: "Chat Service",
    //     path: "/chat",
    //     iconText: "E"
    //   },
    //   {
    //     name: "Email",
    //     path: "/email",
    //     iconText: "B"
    //   },
    // ]
  },

  // { headline: 'PlatForm' },
  // { div: 'platformdiv' },
  // {
  //   name: "Dashboard",
  //   icon: "airplay",
  //   children: [

  //     {
  //       name: "Performance",
  //       path: "/performance",
  //       iconText: "P"
  //     },
  //     {
  //       name: "Compliance",
  //       path: "/security",
  //       iconText: "C"
  //     },
  //     {
  //       name: "AuditLogs",
  //       path: "/auditlogs",
  //       iconText: "A"
  //     },

  //     {
  //       name: "Logs(ATNA)",
  //       path: "/logs",
  //       iconText: "L"
  //     }
  //   ]
  // },
  // {
  //   name: "Cluster-Metrics",
  //   path: "/performance",
  //   icon: "airplay"
  // },
  // {
  //   name: "Compliance",
  //   path: "/security",
  //   icon: "assignment"
  // },
  // {
  //   name: "Monitoring",
  //   path: "/clusterMonitoring",
  //   icon: "grain"
  // },

  // {
  //   name: "Monitoring",
  //   icon: "grain",
  //   children: [
  //     {
  //       name: "Clusters",
  //       path: "/clusterMonitoring",
  //       iconText: "CM"
  //     },

  //   ],
  // },
  // {
  //   name: "AuditLogs",
  //   path: "/auditlogs",
  //   icon: "note"
  // },
  // {
  //   name: "Cluster Logs",
  //   icon: "note",
  //   path: "/auditlogs",
  //   // children: [
  //   //   {
  //   //     name: "container-logs",
  //   //     path: "/auditlogs",
  //   //     iconText: "CL"
  //   //   },

  //   // ],
  // },

  // {
  //   //name: "Service Mesh",
  //   name: "Networking",
  //   path: "/servicemesh",
  //   icon: "account_tree"
  // },
  // {
  //   name: "Networking",
  //   icon: "account_tree",
  //   path: "/servicemesh",
  //   // children: [
  //   //   {
  //   //     name: "NetworkPolicies",
  //   //     path: "/servicemesh",
  //   //     iconText: "NP"
  //   //   },
  //   //   {
  //   //     name: "ServiceGraphs",
  //   //     path: "/servicemesh",
  //   //     iconText: "SG"
  //   //   },
  //   // ],
  // },
  // {
  //   name: "Api Gateway",
  //   path: "/apigateway",
  //   icon: "gradient"
  // },
  // {
  //   name: "Api-Gateway",
  //   icon: "gradient",
  //   path: "/apigateway",
  //   // children: [
  //   //   {
  //   //     name: "Routes",
  //   //     path: "/apigateway",
  //   //     iconText: "API"
  //   //   },
  //   //   {
  //   //     name: "Services",
  //   //     path: "/apigateway",
  //   //     iconText: "SER"
  //   //   },
  //   // ],
  // },
  // {
  //   name: "Secret Store",
  //   path: "/secretstore",
  //   icon: "ballot"
  // },
  // {
  //   name: "Secret Store",
  //   icon: "ballot",
  //   path: "/secretstore",
  //   // children: [
  //   //   {
  //   //     name: "Databases",
  //   //     path: "/secretstore",
  //   //     iconText: "S"
  //   //   },
  //   // ],
  // },
  // {
  //   name: "Logs",
  //   path: "/Logs",
  //   icon: "computer"
  // },
{
    name: "Configure",
    path: "/setup",
    icon: "important_devices"
  },
  // {
  //   name: "Configure",
  //   icon: "important_devices",
  //   path: "/setup",
  //   children: [
  //     {
  //       name: "Setup Checklist",
  //       path: "/setup",
  //       iconText: "S"
  //     },

  //     {
  //       name: "Device Setup",
  //       path: "/device",
  //       iconText: "D"
  //     },
  //     {
  //       name: "Backup/Restore",
  //       path: "/backup",
  //       iconText: "B"
  //     }
  //   ]
  // },

  //########################Tools######################

  // { headline: 'Tools' },






  // {
  //   name: "Configure",
  //   icon: "important_devices",
  //   children: [
  //     {
  //       name: "Setup Checklist",
  //       path: "/setup",
  //       iconText: "S"
  //     },

  //     {
  //       name: "Device Setup",
  //       path: "/device",
  //       iconText: "D"
  //     },
  //     {
  //       name: "Backup/Restore",
  //       path: "/backup",
  //       iconText: "B"
  //     }
  //   ]
  // },

  { div: 'billingdiv' },
  {
    name: "Billing",
    path: "/costanalysis",
    icon: "account_balance"
  },
  // {
  //   name: "Billing",
  //   icon: "account_balance",
  //   children: [
  //     {
  //       name: "Cost Analysis",
  //       path: "/costanalysis",
  //       iconText: "C"
  //     },

  //     {
  //       name: "Summary",
  //       path: "/summary",
  //       iconText: "L"
  //     },
  //     {
  //       name: "Invoice",
  //       path: "/invoice",
  //       iconText: "I"
  //     }

  //   ]
  // },
  { div: 'helpdiv' },
  {
    name: "Help & Support",
    path: "/help",
    icon: "help_outline"
  },


  //########################PlatForms######################





























  // {
  //   name: "Drag and Drop",
  //   icon: "control_camera",
  //   path: "/others/drag-and-drop"
  // },
  // {
  //   name: "Multilevel",
  //   icon: "trending_up",
  //   children: [
  //     {
  //       name: "Level 1",
  //       icon: "list",
  //       children: [
  //         {
  //           name: "Item 1",
  //           path: "/charts/victory-charts",
  //           iconText: "1"
  //         },
  //         {
  //           name: "Item 2",
  //           path: "/charts/react-vis",
  //           iconText: "2"
  //         },
  //         {
  //           name: "Item 3",
  //           path: "/charts/recharts",
  //           iconText: "3"
  //         },
  //         {
  //           name: "Item 4",
  //           path: "/charts/echarts",
  //           iconText: "4"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   name: "Utilities",
  //   icon: "format_list_bulleted",
  //   children: [
  //     {
  //       name: "Color",
  //       path: "/utilities/color",
  //       iconText: "C"
  //     },
  //     {
  //       name: "Spacing",
  //       path: "/utilities/spacing",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Typography",
  //       path: "/utilities/typography",
  //       iconText: "T"
  //     },
  //     {
  //       name: "Display",
  //       path: "/utilities/display",

  //       iconText: "D"
  //     }
  //   ]
  // },
  // {
  //   name: "Sessions",
  //   icon: "trending_up",
  //   children: [
  //     {
  //       name: "Sign in",
  //       iconText: "SI",
  //       path: "/session/signin"
  //     },
  //     {
  //       name: "Sign up",
  //       iconText: "SU",
  //       path: "/session/signup"
  //     },
  //     {
  //       name: "Forgot password",
  //       iconText: "FP",
  //       path: "/session/forgot-password"
  //     },


  //     {
  //       name: "Error",
  //       iconText: "404",
  //       path: "/session/404"
  //     }
  //   ]
  // },

  // {
  //   name: "UI Kits",
  //   icon: "favorite",
  //   badge: { value: "50+", color: "secondary" },
  //   children: [
  //     {
  //       name: "Auto Complete",
  //       path: "/material/autocomplete",
  //       iconText: "A"
  //     },
  //     {
  //       name: "Buttons",
  //       path: "/material/buttons",
  //       iconText: "B"
  //     },
  //     {
  //       name: "Checkbox",
  //       path: "/material/checkbox",
  //       iconText: "C"
  //     },
  //     {
  //       name: "Dialog",
  //       path: "/material/dialog",
  //       iconText: "D"
  //     },
  //     {
  //       name: "Expansion Panel",
  //       path: "/material/expansion-panel",
  //       iconText: "E"
  //     },
  //     {
  //       name: "Form",
  //       path: "/material/form",
  //       iconText: "F"
  //     },
  //     {
  //       name: "Icons",
  //       path: "/material/icons",
  //       iconText: "I"
  //     },
  //     {
  //       name: "Menu",
  //       path: "/material/menu",
  //       iconText: "M"
  //     },
  //     {
  //       name: "Progress",
  //       path: "/material/progress",
  //       iconText: "P"
  //     },
  //     {
  //       name: "Radio",
  //       path: "/material/radio",
  //       iconText: "R"
  //     },
  //     {
  //       name: "Switch",
  //       path: "/material/switch",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Slider",
  //       path: "/material/slider",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Snackbar",
  //       path: "/material/snackbar",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Table",
  //       path: "/material/table",
  //       iconText: "T"
  //     }
  //   ]
  // },

  // {
  //   name: "Map",
  //   icon: "add_location",
  //   path: '/' + sandboxId + '/map',

  // },
  // {
  //   name: "Mapper",
  //   icon: "dashboard",

  //   path: "/:sandboxId/mapf"
  // },




  // {
  //   name: "start",
  //   path: "/s",
  //   icon: "dashboard"
  // },

]

