import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import NavigationReducer from "./NavigationReducer";

import fhir from "./fhir/";
import config from "./config";
import ui from "./ui/";
import sandbox from "./sandbox";
import users from "./users";
import apps from "./apps";
import fhirauth from "./fhirauth";
import persona from "./persona";
import app from "./app";
import patient from "./patient";
import organization from './organization';
import subscription from './Subscriptions';
import auditlogs from './auditlogs';
import metrics from './metrics';
import providerdirectory from './providerdirectory'
import document from "./document";
import company from "./company";
import orgusers from "./orgusers";
import summary from "./summary";
import insurance from "./insurance";
import pharmacy from "./pharmacy";
import hooks from './hooks';
const persistConfig = {
    key: 'root',
    storage,
    transforms: [
        createTransform(
            (inboundState, key) => {
                if (key !== 'sandbox') return inboundState;
                else return {
                    ...inboundState,
                    exportStatus: undefined
                }
            },
            undefined
        ),
    ]
};
const reducers = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  navigations: NavigationReducer,
  config,document,company,summary,orgusers,insurance,pharmacy,hooks,
  fhir,ui,sandbox,sandbox,users,apps,fhirauth,persona,app,patient,organization,subscription,auditlogs,metrics,providerdirectory
});
export default function () {
    return new Promise(resolve => {
        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        const middlewares = [thunk];

        const persistedReducer = persistReducer(persistConfig, reducers);

        let store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
        persistStore(store, {}, () => {
            resolve(store);
        });
    });
}



