import * as types from "./types";
import API from '../../../lib/api';

export function docFetchStarted() {
    return {
        type: types.FETCH_DOCUMENT_REF_START
    }
}
export function docFetchSuccess() {
    return {
        type: types.FETCH_DOCUMENT_REF_SUCCESS
    }
}

export function docFetchError(error) {
    return {
        type: types.FETCH_DOCUMENT_REF_FAIL,
        payload: { error }
    }
}
export function docFetch(documentref) {
    return {
        type: types.FETCH_DOCUMENT_REF,
        payload: { documentref }
    }
}

export function loaddocs() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            //let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(docFetchStarted());
            let url = window.fhirClient.server.serviceUrl.replace('data', 'open') + '/DocumentReference'
            API.get(url, dispatch)
                .then(res => {

                    dispatch(docFetch(res.entry));
                    dispatch(docFetchSuccess());

                })
                .catch(error => dispatch(docFetchError(error)));


        }
    }
}
