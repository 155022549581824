import React, { useState, useEffect } from "react";
import { Breadcrumb, MatxLoading } from "matx";
import TextField from "@mui/material/TextField";

import { Grid, DialogActions, Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HelpIcon from "@material-ui/icons/Help";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Avatar from "react-avatar";
import Switch from "@material-ui/core/Switch";
import {
  loadcompanydetails,
  editCompanydetails,
  app_setScreen,
} from "../../redux/actions/action-creators";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withErrorHandler from "../../MatxLayout/SharedCompoents/Auxilary/withErrorHandler";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Badge from "@material-ui/core/Badge";
import { countries } from "../countries";
import BasicModal from "matx/components/BasicModal";
import ImageCrop from "../Directory/ImageCrop";
import { functions, storage } from "../../auth/firebaseconfig";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { MatxSnackbar } from "matx";
import { Icon } from "@material-ui/core";
import { Stack } from "@mui/material";
import Summary from "matx/components/Summary";
import { companyProfile } from "./formFields";

const Axios = require("axios");
const emailRegex = RegExp(/^[^@]+@[^@]+\.[^@]+$/);
const phoneRegex = RegExp(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/);
const faxRegex = RegExp(/^\+?[0-9]{6,}$/);
const zipRegex = RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
const einvalidate = RegExp(
  /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/
);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));
const LoadCompanyProfile = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [title, setTitle] = useState("");
  const initialValues = {
    active: "",
    adress: "",
    adressLine2: "",
    aliasName: "",
    city: "",
    code: "",
    country: "",
    createdTimestamp: "",
    created_by: "",
    description: "",
    email: "",
    fax: "",
    id: "",
    name: "",
    organizationType: "",
    orgnizationEndpoint: "",
    phone: "",
    sandboxId: "",
    state: "",
    zip: "",
    ein_number: "",
    profile_pic: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [foemv, setformv] = useState("");
  React.useEffect(() => {
    props.app_setScreen("companyprofile");
    props.loadcompanydetails();
  }, []);

  useEffect(() => {
    let data = props.details;
    if (data) {
      const fetchUserData = async () => {
        const profilePics = await loadImages(data);
        console.log(profilePics,"---profilePics")
        data = {
          ...data,
          profile_pic: profilePics!==undefined && profilePics!==null?profilePics :"https://dummyimage.com/250x100/bfb4bf/fff",
        };
        setTitle(props.details?.name);
        setFormValues(data)
        setformv(props.details);
      };
      fetchUserData();
    }
  }, [props.details]);

  const loadImages = async (data) => {
    if (data?.name) {
      const urls = await fetchImages(data?.name);
      if (urls?.length > 0) {
        return urls[0];
      }
      return null; // Return null if no image found
    }
  };

  const fetchImages = async (name) => {
    try {
      const result = await storage
        .ref()
        .child(`documents/org_dp/${name}/`)
        .listAll();
      const urlPromises = result.items.map((imageRef) =>
        imageRef.getDownloadURL()
      );
      return Promise.all(urlPromises);
    } catch (error) {
      console.error("Error fetching images:", error);
      return []; // Return an empty array in case of an error
    }
  };

  const [edit, setEdit] = useState(false);
  const handledit = () => {
    setEdit(true);
  };
  const handleeditmode = () => {
    setEdit(false);
    setFormValues(props.details);
    setFile(props.details?.profile_pic);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.editCompanydetails(formValues);
    setFormValues(initialValues);
  };
  const [filedError, setFieldError] = useState(initialValues);
  const formErrors = { ...filedError };
  const [isError, setIsError] = useState(false);
  const [profilePic, setProfilePic] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //
    const lengthValidate = value.length > 0 && value.length < 3;
    switch (name) {
      case "name":
        formErrors.name = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "aliasName":
        formErrors.aliasName = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "description":
        formErrors.description = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "code":
        formErrors.code = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "ein_number":
        formErrors.ein_number = einvalidate.test(value)
          ? ""
          : "Invalid EIN number";
        break;
      case "email":
        formErrors.email = emailRegex.test(value) ? "" : "Email id is invalid";
        break;
      case "adress":
        formErrors.adress = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "adressLine2":
        formErrors.adressLine2 = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "city":
        formErrors.city = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "zip":
        formErrors.zip = zipRegex.test(value)
          ? ""
          : "Must be with formats: 12345 or 12345-6789.";
        break;
      case "phone":
        formErrors.phone = phoneRegex.test(value)
          ? ""
          : "Must be with formats: (123)456-7890.";
        break;
      case "state":
        formErrors.state = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "country":
        formErrors.country = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;

      case "fax":
        formErrors.fax = phoneRegex.test(value)
          ? ""
          : "Must be with formats: (123)456-7890.";
        //formErrors.fax = faxRegex.test(value) ? "" : "Must be with formats : 12345."

        break;
      default:
        break;
    }
    setFieldError({
      ...formErrors,
    });
    formErrors.name.length !== 0
      ? setIsError(true)
      : formErrors.aliasName.length !== 0
      ? setIsError(true)
      : formErrors.description.length !== 0
      ? setIsError(true)
      : formErrors.code.length !== 0
      ? setIsError(true)
      : //             : formErrors.type.length !== 0 ? setIsError(true)
      formErrors.adress.length !== 0
      ? setIsError(true)
      : formErrors.adressLine2.length !== 0
      ? setIsError(true)
      : formErrors.city.length !== 0
      ? setIsError(true)
      : formErrors.state.length !== 0
      ? setIsError(true)
      : formErrors.ein_number.length !== 0
      ? setIsError(true)
      : formErrors.zip.length !== 0
      ? setIsError(true)
      : formErrors.fax.length !== 0
      ? setIsError(true)
      : formErrors.email.length !== 0
      ? setIsError(true)
      : formErrors.orgnizationEndpoint.length !== 0
      ? setIsError(true)
      : setIsError(false);

    setFormValues({ ...formValues, [name]: value });
  };

  const isEmpty =
    foemv !== undefined
      ? formValues.name &&
        formValues.name.length > 0 &&
        formValues.code &&
        formValues.code.length > 0 &&
        formValues.ein_number &&
        formValues.ein_number.length > 0
      : false;
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [file, setFile] = useState(undefined);

  const uploadImage = async (data) => {
    let users = JSON.parse(localStorage.getItem("sbmUserId"));
    let domain = users.substring(users.lastIndexOf("@") + 1);
    const formData = new FormData();
    formData.append("file", data);
    firebaseUpload(data, domain);
    // formData.append("file", data);
    await fetch(
      `https://manager-api.collabkare.com/organization/${
        domain.split(".")[0]
      }/uploadFile`,
      {
        method: "put",
        body: formData,
        headers: {
          Authorization:
            window.fhirClient &&
            window.fhirClient.server &&
            window.fhirClient.server.auth
              ? `Bearer ${window.fhirClient.server.auth.token}`
              : undefined,

          Accept: "application/json",
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          console.log("success");
        }
      })
      .catch((err) => console.log(err));
  };

  async function firebaseUpload(croppedBlob, domain) {
    try {
      const storageRef = ref(
        storage,
        `documents/org_dp/${domain?.split(".")[0]}/${domain}`
      );
      const uploadTask = uploadBytesResumable(storageRef, croppedBlob);
      uploadTask.on("state_changed", () => {
        setModalOpen(false);
        setShowSnackbar(true);
        setSnackbarMsg("Image uploaded successfully.");
        props.loadcompanydetails();
      });
    } catch (error) {
      setModalOpen(false);
      setShowSnackbar(true);
      setSnackbarMsg("Image not uploaded try again.");
      props.loadcompanydetails();
    }
  }

  const [status, setstatus] = useState(false);
  const handlestatusmode = () => {
    setstatus(true);
  };
  const handlestatus = () => {
    setstatus(false);
  };

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangecheck = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    setOpen(true);
  };

  let [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setEdit(false);
  };
  const deactive = () => {
    Axios({
      method: "put",
      url: "https://manager-api.collabkare.com/organization/disable/demo",
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(false);
  };
  const editfun = () => {
    setEdit(true);
  };

  const DeleteImage = () => {
    let users = JSON.parse(localStorage.getItem("sbmUserId"));
    let domain = users.substring(users.lastIndexOf("@") + 1);
    const storageRef = ref(
      storage,
      `documents/org_dp/${domain?.split(".")[0]}/${domain}`
    );

    // Delete the file
    deleteObject(storageRef)
      .then(() => {
        // File deleted successfully
        setModalOpen(false);
        setShowSnackbar(true);
        setSnackbarMsg("Image deleted successfully.");
        props.loadcompanydetails();
      })
      .catch((error) => {
        // An error occurred while deleting the file
        console.error("Error deleting file:", error);
        setShowSnackbar(true);
        setSnackbarMsg("Error deleting image.");
      });
  };

  if (props.fetching) {
    return (
      <div>
        <MatxLoading />
      </div>
    );
  }

  return (
    <div>
      <Summary
        formValues={formValues}
        formFields={companyProfile}
        title={title}
        service={"practice"}
        editfun={editfun}
        uploadImage={setModalOpen}
        image={formValues?.profile_pic}
        tagline={"Organization profile"}
        DeleteImage={DeleteImage}
      />
      <BasicModal
        title={"Set Orginization Picture"}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <div style={{ height: "50px !important" }}>
          <ImageCrop handleImageUpload={uploadImage} />
        </div>
      </BasicModal>
      <Dialog
        open={edit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-tite"
        aria-describedby="alert-dialog-descriptin"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ backgroundColor: "#fff", textAlign: "center" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
              color: "#000",
            }}
          >
            <span>Edit organisation details</span>
            <span style={{ cursor: "pointer" }}>
              <Icon onClick={handleClose}> close</Icon>
            </span>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            {!props.fetching && formValues !== undefined ? (
              <Grid container spacing={1} direction="row" className="p-5s">
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="standard-basic"
                    label="Organization Name "
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    disabled
                    name="name"
                    value={formValues.name}
                    required
                    fullWidth
                    error={filedError.name !== ""}
                    helperText={
                      filedError.name !== "" ? `${filedError.name}` : ""
                    }
                    placeholder="Human Readable Name for Your Organization"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="organisationalias"
                    label="Organization Alias Name"
                    onChange={handleChange}
                    name="aliasName"
                    variant="outlined"
                    value={formValues.aliasName}
                    error={filedError.aliasName !== ""}
                    helperText={
                      filedError.aliasName !== ""
                        ? `${filedError.aliasName}`
                        : ""
                    }
                    margin="dense"
                    //  required
                    fullWidth
                    placeholder="Organization Alias Name"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Organization Description"
                    onChange={handleChange}
                    name="description"
                    variant="outlined"
                    value={formValues.description}
                    error={filedError.description !== ""}
                    helperText={
                      filedError.description !== ""
                        ? `${filedError.description}`
                        : ""
                    }
                    margin="dense"
                    size="small"
                    // required
                    fullWidth
                    placeholder="Organization Description"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="hospitalid"
                    label="Company ID"
                    onChange={handleChange}
                    disabled
                    name="code"
                    variant="outlined"
                    value={formValues.code}
                    error={filedError.code !== ""}
                    helperText={
                      filedError.code !== "" ? `${filedError.code}` : ""
                    }
                    margin="dense"
                    required
                    fullWidth
                    placeholder="Company ID"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="demo-simple">Type</InputLabel>
                    <Select
                      id="demo-simple"
                      name="organizationType"
                      value={formValues.organizationType}
                      onChange={handleChange}
                      // error={filedError.organizationType !== ""}
                      // helperText={filedError.organizationType !== "" ? `${filedError.organizationType}` : ""}
                      variant="outlined"
                      label="Type"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"prov"}>Healthcare Provider</MenuItem>
                      <MenuItem value={"dept"}>Hospital Department</MenuItem>
                      <MenuItem value={"team"}>Organisation team</MenuItem>
                      <MenuItem value={"govt"}>Government</MenuItem>
                      <MenuItem value={"ins"}>insurance Company</MenuItem>
                      <MenuItem value={"pay"}>Payer</MenuItem>
                      <MenuItem value={"edu"}>Education institute</MenuItem>
                      <MenuItem value={"reli"}>Religious institute</MenuItem>
                      <MenuItem value={"crs"}>
                        Clinical Research Sponsor
                      </MenuItem>
                      <MenuItem value={"cg"}>Community Group</MenuItem>
                      <MenuItem value={"bus"}>Non-Healthcare Business</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Address Line 1"
                    label="Address Line 1"
                    placeholder="Address Line 1"
                    onChange={handleChange}
                    name="adress"
                    variant="outlined"
                    error={filedError.adress !== ""}
                    helperText={
                      filedError.adress !== "" ? `${filedError.adress}` : ""
                    }
                    value={formValues.adress}
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Address Line 2"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                    onChange={handleChange}
                    name="adressLine2"
                    variant="outlined"
                    error={filedError.adressLine2 !== ""}
                    helperText={
                      filedError.adressLine2 !== ""
                        ? `${filedError.adressLine2}`
                        : ""
                    }
                    value={formValues.adressLine2}
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="City"
                    label="City"
                    placeholder="City"
                    onChange={handleChange}
                    variant="outlined"
                    value={formValues.city}
                    error={filedError.city !== ""}
                    helperText={
                      filedError.city !== "" ? `${filedError.city}` : ""
                    }
                    name="city"
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="State"
                    label="State"
                    placeholder="State"
                    onChange={handleChange}
                    value={formValues.state}
                    error={filedError.state !== ""}
                    helperText={
                      filedError.state !== "" ? `${filedError.state}` : ""
                    }
                    variant="outlined"
                    name="state"
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="zip"
                    label="zip"
                    onChange={handleChange}
                    placeholder="zip"
                    value={formValues.zip}
                    error={filedError.zip !== ""}
                    helperText={
                      filedError.zip !== "" ? `${filedError.zip}` : ""
                    }
                    variant="outlined"
                    name="zip"
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel id="countrys">Country</InputLabel>
                    <Select
                      id="countrys"
                      variant="outlined"
                      name="country"
                      value={formValues.country}
                      onChange={handleChange}
                      label="country"
                    >
                      {countries
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                        .map((dropdown) => (
                          <MenuItem key={dropdown.label} value={dropdown.code}>
                            {dropdown.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Telephone"
                    label="Telephone"
                    placeholder="Telephone"
                    value={formValues.phone}
                    error={filedError.phone !== ""}
                    helperText={
                      filedError.phone !== "" ? `${filedError.phone}` : ""
                    }
                    variant="outlined"
                    name="phone"
                    onChange={handleChange}
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Fax"
                    label="Fax"
                    placeholder="Fax"
                    onChange={handleChange}
                    variant="outlined"
                    name="fax"
                    value={formValues.fax}
                    error={filedError.fax !== ""}
                    helperText={
                      filedError.fax !== "" ? `${filedError.fax}` : ""
                    }
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Email"
                    label="Email"
                    placeholder="Email"
                    disabled
                    onChange={handleChange}
                    variant="outlined"
                    name="email"
                    //required
                    value={formValues.email}
                    error={filedError.email !== ""}
                    helperText={
                      filedError.email !== "" ? `${filedError.email}` : ""
                    }
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Organisation endpoint(optional)"
                    label="Organisation endpoint(optional)"
                    placeholder="Organisation endpoint(optional)"
                    onChange={handleChange}
                    name="orgnizationEndpoint"
                    value={formValues.orgnizationEndpoint}
                    error={filedError.orgnizationEndpoint !== ""}
                    helperText={
                      filedError.orgnizationEndpoint !== ""
                        ? `${filedError.orgnizationEndpoint}`
                        : ""
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Type"
                    label="Organisation's Tax ID"
                    variant="outlined"
                    onChange={handleChange}
                    name="ein_number"
                    error={filedError.ein_number !== ""}
                    helperText={
                      filedError.ein_number !== ""
                        ? `${filedError.ein_number}`
                        : ""
                    }
                    value={formValues.ein_number}
                    margin="dense"
                    required
                    fullWidth
                    placeholder="Organisation's Tax ID"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <DialogActions>
                    <Button variant="outlined" onClick={handleeditmode}>
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={!isEmpty || isError}
                      //  disabled={isError}
                      type="submit"
                    >
                      update
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            ) : (
              []
            )}
          </form>
        </DialogContent>
      </Dialog>
      <div>
        <MatxSnackbar
          open={showSnackbar}
          setOpen={setShowSnackbar}
          message={snackbarMsg}
        />
      </div>
    </div>
  );
};
//export default CompanyProfile;
const mapStateToProps = (state) => {
  return {
    details: state.company.details,
    Error: state.company.Error,
    fetching: state.company.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadcompanydetails,
      editCompanydetails,
      app_setScreen,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(LoadCompanyProfile));
