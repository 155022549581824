import React from "react";

const settingRoutes = [
    {
        path: "/:sandboxId/settings",
        component: React.lazy(() => import("./settings"))
    },
];

export default settingRoutes;
