import React from "react";

const healthinfoRoutes = [
  {
    path: "/empi",
    component: React.lazy(() => import("./empi"))
  },
  {
    path: "/logs",
    component: React.lazy(() => import("./Logs"))
  },
  {
    path: "/fhirdata",
    component: React.lazy(() => import("./fhirdataex"))
  },
  {
    path: "/documentservice",
    component: React.lazy(() => import("./document"))
  },
  {
    path: "/fhirconnect",
    component: React.lazy(() => import("./Fhirpdex"))
  },
];

export default healthinfoRoutes;