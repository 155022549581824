import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';



import Auxilary from './Auxilary';



const withErrorHandler = (WrappedComponent) => {

    class WithErrorHandler extends Component {

        componentDidMount () {

            !window.fhirClient && this.props.history.location.pathname !== '/after-auth' && this.props.history.push('/');

        }



        render () {

            return <Auxilary>

                    <WrappedComponent {...this.props} />

                </Auxilary>

        }

    }



    return withRouter(WithErrorHandler);

};



export default withErrorHandler;

