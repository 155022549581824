import * as types from "../../actions/action-creators/types";
import initialState from "./init";

export default (state = initialState, action) => {
    state = Object.assign({}, state);
 
    switch (action.type) {
        case types.SET_CREATED_ORG:
            state.createdOrg = action.payload.org;
            break;

        case types.SET_ORG:
            let newOrganization = state.organization.filter(i => i.id !== action.payload.org.id);
            newOrganization.push(action.payload.org);
            state.organization = newOrganization;
            break;

        case types.SET_CREATED_ORG_CSV:
            state.createdOrg = action.payload.orgCsv;
            break;

        case types.SET_ORG_CSV:
            let newOrganizationCsv = state.organization.filter(i => i.id !== action.payload.orgCsv.id);
            newOrganizationCsv.push(action.payload.orgCsv);
            state.organization = newOrganization;
            break;
             
        case "persist/REHYDRATE":
            state = action.payload ? action.payload.organization : state;
            break;
    }
    return state;
};
