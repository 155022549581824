// src/Banner.js

import React from 'react';
import './Banner.css'; // Importing the CSS file for styles
import BannerImg from '../../assets/images/banner_img.png'

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-text">
        <div className='h1Text'>Making Health Easy and Accessible</div>
        <p>
          At CollabKare, we prioritize our patient's health and well-being, 
          and we strive to provide compassionate care with exceptional results.
        </p>
      </div>
      <div className="banner-image">
        <img src={BannerImg} alt="Placeholder" height={"450px"}/>
      </div>
    </div>
  );
};

export default Banner;
