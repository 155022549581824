import * as types from "./types";
import API from '../../../lib/api';

export function setSandboxApps(apps) {
    return { type: types.SET_SANDBOX_APPS, payload: { apps } }
}

export function setSandboxAppsLoading(loading) {
    return { type: types.SET_SANDBOX_APPS_LOADING, payload: { loading } }
}

export function setSandboxAppLoading(loading) {
    return { type: types.SET_SANDBOX_APP_LOADING, payload: { loading } }
}

export function appCreating(creating) {
    return { type: types.SET_SANDBOX_APPS_CREATING, payload: { creating } }
}

export function appDeleting(deleting) {
    return { type: types.SET_SANDBOX_APPS_DELETING, payload: { deleting } }
}

export function setApp(app) {
    return { type: types.SET_APP, payload: { app } }
}

export function setCreatedApp(app) {
    return { type: types.SET_CREATED_APP, payload: { app } }
}

export function createApp(app) {
    return (dispatch, getState) => {
        let state = getState();

        dispatch(appCreating(true));
        let url = process.env.sandboxManagerApiUrl + "/app";
        // let url = state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl + "/app";
        let scope = app.scope.length > 2
            ? app.scope.split(' ')
            : app.patientScoped
                ? ["launch", "patient/*.*", "profile", "openid"]
                : ["launch", "user/*.*", "profile", "openid"];
        // let clientJSON = {
        //     scope,
        //     clientName: app.clientName,
        //     launchUri: app.launchUri,
        //     redirectUris: app.redirectUris.split(','),
        //     grantTypes: ["authorization_code"],
        //     tokenEndpointAuthMethod: app.tokenEndpointAuthMethod,
        //     accessTokenValiditySeconds: 3600,
        //     idTokenValiditySeconds: 3600,
        //     refreshTokenValiditySeconds: 31557600
        // };
        let clientJSON = {
            _id: app.clientName,
            coreOAuth2ClientConfig: {
                userpassword: "secret",
                redirectionUris: {
                    value: app.redirectUris.split(','),
                    inherited: false
                },
                clientType: {
                    value: "",
                    inherited: false
                },
        
                refreshTokenLifetime: {
                    value: 0,
                    inherited: false
                },
                scopes: {
                    value:scope,
                    // value: [
                    //     "patient/Patient.read",
                    //     "patient/*.read",
                    //     "openid","profile",
                    //     "patient/Observation.read",
                    //     "user/*.read",
                    //     "launch",
                    //     "patient/*.write",
                    //     "fhirUser",
                    //     "patient/Observation.write"
                    // ],
                    inherited: false
                },
                clientName: {
                    value: [
                        app.clientName
                    ],
                    inherited: false
                }
        
            },
            advancedOAuth2ClientConfig: {
        
                logoUri: {
                    inherited: false,
                    value: []
                },
                name: {
                    inherited: false,
                    value: [app.clientName]
                },
            grantTypes: {
                    inherited: false,
                    value: [
                        // "authorization_code",
                        // "refresh_token"
                    ]
                }
            },
            coreOpenIDClientConfig: {
                postLogoutRedirectUri: {
                    inherited: false,
                    value: ["https://myaccount.collabkare.com/login"]
                }
            }
        }
        if (app.offlineAccess) {
            clientJSON.scope.coreOAuth2ClientConfig.value.push("offline_access");
            clientJSON.advancedOAuth2ClientConfig.grantTypes.value.push("refresh_token");
            clientJSON.requireAuthTime = false;
        }

        if (app.tokenEndpointAuthMethod !== "NONE") {
            clientJSON.tokenEndpointAuthMethod = "SECRET_BASIC";
            clientJSON.coreOAuth2ClientConfig.clientType.value = "Confidential";
        } else {
            clientJSON.tokenEndpointAuthMethod = "NONE";
            clientJSON.coreOAuth2ClientConfig.clientType.value= "Public";
        }
        
        let newApp = {
            launchUri: app.launchUri,
            redirectUris: app.redirectUris.split(','),
            clientName: app.clientName,
            createdBy: state.users.oauthUser,
            sandbox: state.sandbox.sandboxes.find(i => i.sandboxId === sessionStorage.sandboxId),
            briefDescription: app.briefDescription,
            samplePatients: app.samplePatients,
            clientJSON: JSON.stringify(clientJSON)
            //clientJSON: clientJSON
        };

        API.post(url, newApp, dispatch)
            .then(createdApp => {
                if (app.logoFile) {
                    let formData = new FormData();
                    formData.append("file", app.logoFile);
                    // url = state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl + "/app/" + createdApp.id + "/image";
                    url = process.env.sandboxManagerApiUrl + "/app/" + createdApp.id + "/image";
                    API.post(url, formData, dispatch, true)
                        .then(() => setTimeout(() => {
                            dispatch(setCreatedApp(createdApp));
                            dispatch(loadSandboxApps());
                        }, 550))
                        .catch(() => {
                            dispatch(loadSandboxApps());
                            dispatch(appCreating(false));
                        });
                } else {
                    dispatch(setCreatedApp(createdApp));
                    dispatch(loadSandboxApps());
                    dispatch(appCreating(false));
                }
            })
            .catch(() => setTimeout(() => dispatch(appCreating(false)), 550));
    }
}

export function updateApp(newValues, originalApp, changes) {
    return (dispatch, getState) => {
        let state = getState();
console.log(newValues)
        dispatch(appCreating(true));
        dispatch(setCreatedApp());
        let url = `${process.env.sandboxManagerApiUrl}/app/${originalApp.id}`;
     
        //let url = `${state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl}/app/${originalApp.id}`;
        let newApp = Object.assign({}, originalApp, {
            launchUri: newValues.launchUri,
            briefDescription: newValues.briefDescription,
            samplePatients: newValues.samplePatients,
            logoUri: newValues.clientJSON.logoUri ? newValues.clientJSON.logoUri : null,
            clientJSON: JSON.stringify(
                Object.assign({},newValues.clientJSON,
                {
                   
                    coreOAuth2ClientConfig: {
                      userpassword: "secret",
                      redirectionUris: {
                        value: newValues.redirectUris.split(','),
                        inherited: false,
                      },
                      clientType: {
                        value: newValues.clientJSON.coreOAuth2ClientConfig.clientType.value,
                        inherited: false,
                      },
              
                      refreshTokenLifetime: {
                        value: 0,
                        inherited: false,
                      },
                      scopes: {
                        value: newValues.scope.split(' '),
                       
                        inherited: false,
                      },
                      clientName: {
                        value: [newValues.clientName],
                        inherited: false,
                      },
                    },
                }
            )
            //)
        )});
console.log(newApp)
        // clientJSON: JSON.stringify(Object.assign({}, newValues.clientJSON, {
              
        //     'coreOAuth2ClientConfig.clientType.value': newValues.clientName,
            
            
        //     launchUri: newValues.launchUri,
        //     'coreOAuth2ClientConfig.redirectionUris.value': newValues.redirectUris.split(','),
        //     tokenEndpointAuthMethod: newValues.tokenEndpointAuthMethod,
        //     'coreOAuth2ClientConfig.scopes.value': newValues.scope.split(' ')
        // }))
        let updateImage = () => {
            if (newValues.logoFile) {
                let formData = new FormData();
                formData.append("file", newValues.logoFile);
                // url = state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl + "/app/" + originalApp.id + "/image";

                url = process.env.sandboxManagerApiUrl + "/app/" + originalApp.id + "/image";
                API.post(url, formData, dispatch, true).finally(() => setTimeout(() => dispatch(loadSandboxApps()), 550));
            } else if (!newValues.logoFile) {
                url = process.env.sandboxManagerApiUrl + "/app/" + originalApp.id + "/image";
                API.delete(url, dispatch).finally(() => dispatch(loadSandboxApps()));
            } else {
                dispatch(loadSandboxApps());
            }
        };

        if (changes.length && !(changes.length === 1 && changes[0] === 'image')) {
            API.put(url, newApp, dispatch)
                .then(() => {
                    if (changes.indexOf('image') >= 0) {
                        updateImage();
                    } else {
                        dispatch(loadSandboxApps());
                    }
                })
                .catch(() => {
                    dispatch(loadSandboxApps());
                });
        } else if (changes.indexOf('image') >= 0) {
            updateImage();
        } else {
            setTimeout(() => dispatch(loadSandboxApps()), 2500);
        }
    }
}

export function deleteApp(app) {
    return (dispatch, getState) => {
        let state = getState();
        dispatch(appDeleting(true));
        let url = process.env.sandboxManagerApiUrl + "/app/" + app.id;
        // let url = state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl + "/app/" + app.id;
        API.delete(url, dispatch).finally(() => dispatch(loadSandboxApps()));
    }
}

export function loadApp(app, callback) {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            dispatch(setSandboxAppLoading(true));

            // let url = `${state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl}/app/${app.id}`;
            let url = `${process.env.sandboxManagerApiUrl}/app/${app.id}`;
            API.get(url, dispatch).then(app => {
                dispatch(setApp(app));
                callback && callback(app);
            }).finally(() => dispatch(setSandboxAppLoading(false)));
        }
    }
}

export function loadSandboxApps() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            dispatch(setSandboxAppsLoading(true));

            //let url = state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl + "/app?sandboxId=" + sessionStorage.sandboxId;
            let url = process.env.sandboxManagerApiUrl + "/app?sandboxId=" + sessionStorage.sandboxId;
            API.get(url, dispatch)
                .then(apps => dispatch(setSandboxApps(apps)))
                .finally(() => {
                    dispatch(setSandboxAppsLoading(false));
                    dispatch(appDeleting(false));
                    dispatch(appCreating(false));
                });
        }
    }
}
