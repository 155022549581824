import * as actionTypes from "./types";
import API from '../../../lib/api'
export function vhcompanyFetchStarted() {
    return {
        type: actionTypes.FETCH_VHCOMPANY_START
    }
}
export function vhcompanyFetchSuccess() {
    return {
        type: actionTypes.FETCH_VHCOMPANY_SUCCESS
    }
}

export function vhcompanyFetchError(error) {
    return {
        type: actionTypes.FETCH_VHCOMPANY_FAIL,
        payload: { error }
    }
}
export function vhcompanyFetch(details) {
    return {
        type: actionTypes.FETCH_VHCOMPANY,
        payload: { details }
    }
}


export function vhloadcompanydetails() {
    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(vhcompanyFetchStarted());
            let url = `${process.env.sandboxManagerApiUrl}/organization/${sessionStorage.sandboxId}`
            API.get(url, dispatch)
                .then(res => {
                    dispatch(vhcompanyFetchSuccess());
                    dispatch(vhcompanyFetch(res));
                   // console.log(res)

                })
                .catch(error => dispatch(vhcompanyFetchError(error)));


        }
    }
}

export function vhcompanyeditFetchStarted() {
    return {
        type: actionTypes.FETCH_VHCOMPANYEDIT_START
    }
}
export function vhcompanyeditFetchSuccess() {
    return {
        type: actionTypes.FETCH_VHCOMPANYEDIT_SUCCESS
    }
}

export function vhcompanyEDITFetchError(error) {
    return {
        type: actionTypes.FETCH_VHCOMPANYEDIT_FAIL,
        payload: { error }
    }
}
export function vheditCompanydetails(val) {
    let data =
    {
        active: val.active,
        adress: val.adress,
        adressLine2: val.adressLine2,
        aliasName: val.aliasName,
        city: val.city,
        code: val.code,
        country: val.country,
        createdTimestamp: val.createdTimestamp,
        created_by: val.created_by,
        description: val.description,
        email: val.email,
        fax: val.fax,
        id: val.id,
        name: val.name,
        organizationType: val.organizationType,
        orgnizationEndpoint: val.orgnizationEndpoint,
        phone: val.phone,
        sandboxId: val.sandboxId,
        state: val.state,
        zip: val.zip,
        ein_number: val.ein_number,
        profile_pic: val.profile_pic
    }

    return (dispatch, getState) => {
        if (window.fhirClient) {
            let state = getState();
            console.log(data)
            //let configuration = state.config.xsettings.data.sandboxManager;
            dispatch(vhcompanyeditFetchStarted());
            let url = `${process.env.sandboxManagerApiUrl}/organization/${sessionStorage.sandboxId}`
            API.put(url, data, dispatch, true)

                .finally(() => setTimeout(() => {
                    dispatch(vhcompanyeditFetchSuccess())
                    setTimeout(() => {
                        dispatch(vhloadcompanydetails())
                    },600)
                    
                }, 550))

                .catch(error => dispatch(vhcompanyEDITFetchError(error)));


        }
    }
}






