import React from "react";
import * as echarts from 'echarts/core';// Import echarts from the correct package
import ReactEcharts from "echarts-for-react";
import PropTypes from "prop-types";
import { EchartTheme } from "matx";
import { withStyles } from "@material-ui/core/styles";

const EchartCreator = (props) => {
  const myChart = React.useRef(null);

  React.useEffect(() => {
    myChart.current = echarts.init(myChart.current); // Initialize the chart
    myChart.current.registerTheme("echarts-theme", EchartTheme(props.theme));
  }, [props.theme]);

  return (
    <ReactEcharts
      ref={myChart}
      style={{ height: props.height, width: "100%" }}
      option={props.option}
      lazyUpdate={true}
      theme="echarts-theme"
    />
  );
};

EchartCreator.propTypes = {
  height: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles({}, { withTheme: true })(EchartCreator);
