import * as types from "./types";
import API from '../../../lib/api';
//import { version } from "moment";


export function setOrg(org) {
    return { type: types.SET_ORG, payload: { org } }
}
export function setCreatedOrg(org) {
    return { type: types.SET_CREATED_ORG, payload: { org } }
}
export function orgCreating(creating) {
    return { type: types.SET_SANDBOX_ORGS_CREATING, payload: { creating } }
}
export function setOrgCsv(orgCsv) {
    return { type: types.SET_ORG_CSV, payload: { orgCsv } }
}
export function setCreatedOrgCsv(orgCsv) {
    return { type: types.SET_CREATED_ORG_CSV, payload: { orgCsv } }
}
export function orgCsvCreating(creating) {
    return { type: types.SET_SANDBOX_ORGS_CSV_CREATING, payload: { creating } }
}

export function createOrg(org) {
    return (dispatch, getState) => {
        let state = getState();

        dispatch(orgCreating(true));

        //let url = state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl + "/org/save";
        //  let url=state.config.xsettings.data.sandboxManager.fhirGuiUrl+"/fhir/organizationupload";
        let url = process.env.fhirGuiUrl + "/fhir/organizationupload";

        let clientJSON = {
            //scope,
            clientName: org.orgName,
            launchUri: org.orgUrl,
            //redirectUris: org.redirectUris.split(','),
            grantTypes: ["authorization_code"],
            //tokenEndpointAuthMethod: org.tokenEndpointAuthMethod,
            accessTokenValiditySeconds: 3600,
            idTokenValiditySeconds: 3600,
            refreshTokenValiditySeconds: 31557600
        };
        if (org.offlineAccess) {
            clientJSON.scope.push("offline_access");
            clientJSON.grantTypes.push("refresh_token");
            clientJSON.requireAuthTime = false;
        }
        // let newOrg = {
        //     'Organization.name': org.orgName,
        //     'Organization.alias':org.orgAliasName,
        //     //url: org.orgUrl,
        //     'Organization.address' : org.orgAddress,
        //     //orgContact:org.orgContact,
        //     'Organization.type' : org.orgType,
        //     'Organization.active' : org.active,
        //     'Organization.telecom.value' : org.orgContact,
        //     'Organization.partOf' : org.orgPartOf,
        //     'Organization.contact.name':org.contName,
        //     'Organization.contact.purpose':org.contPurpose,
        //     'Organization.contact.address': org.contAddress,
        //     'Organization.contact.telecom.value' : org.contTelecom,
        //     'Organization.endpoint' : org.orgEndpoint
        //     // createdBy : state.users.oauthUser,through it in one direction until it is rendered on the screen
        //     // sandbox : state.sandbox.sandboxes.find(i => i.sandboxId === sessionStorage.sandboxId),
        //     // clientJSON : JSON.stringify(clientJSON)

        // };
        let formData2 = new FormData();
        formData2.append('Organization.name', org.orgName);
        formData2.append('Organization.alias', org.orgAliasName);
        //formData2.append('Organization.address', org.orgAddress);
        formData2.append('Organization.address.line1', org.orgaddr1);
        formData2.append('Organization.address.line2', org.orgaddr2);
        formData2.append('Organization.address.state', org.orgState);
        formData2.append('Organization.address.city', org.orgCity);
        formData2.append('Organization.address.postalCode', org.orgPincode);
        formData2.append('Organization.address.country', org.orgCountry);
        formData2.append('Organization.type', org.orgType);
        formData2.append('Organization.active', org.active);
        formData2.append('Organization.telecom.value', org.orgContact);
        formData2.append('Organization.partOf', org.orgPartOf);
        formData2.append('Organization.contact.name', org.contName);
        formData2.append('Organization.contact.purpose', org.contPurpose);
        //formData2.append('Organization.contact.address', org.contAddress);
        //formData2.append('Organization.contact.telecom.value', org.contTelecom);
        formData2.append('Organization.endpoint', org.orgEndpoint);

        formData2.append("file", org.logoFile);

        let locservice = window.sessionStorage.getItem('sandboxId');
        formData2.append('tenantId', locservice);
        formData2.append('fhirversion', sessionStorage.getItem('version'));

        //console.log(fromData2);

        //console.log(org.orgContact);

        API.post(url, formData2, dispatch, true)
            .then(createdOrg => {

                dispatch(setCreatedOrg(createdOrg));
                alert('Organization Details Saved Successfully...');
                //if (org.logoFile) {
                //let formData = new FormData();
                //formData2.append("file", org.logoFile);
                // url = state.config.xsettAPI.post(url, formData, dispatch, true)
                //         .then(() => setTimeout(() => {
                // dispatch(setCreatedOrg(createdOrg));
                //         }, 550))
                //         .catch(() => {
                //             dispatch(orgCreating(false));
                //         });ings.data.sandboxManager.sandboxManagerApiUrl + "/org/" + createdOrg.id + "/image";
                //url = "https://app.collabnotes.io/fhir/organizationupload";
                //    API.post(url, formData, dispatch, true)
                //         .then(() => setTimeout(() => {
                //             dispatch(setCreatedOrg(createdOrg));
                //         }, 550))
                //         .catch(() => {
                //             dispatch(orgCreating(false));
                //         });
                // } else {
                //     dispatch(setCreatedOrg(createdOrg));
                //     dispatch(orgCreating(false));
                // }
            })
            .catch(() => setTimeout(() => dispatch(orgCreating(false)), 550));
    }
}

export function createOrgCsv(orgCsv) {
    return (dispatch, getState) => {
        let state = getState();

        dispatch(orgCsvCreating(true));

        if (orgCsv.csvFile) {
            let formData1 = new FormData();
            formData1.append('file', orgCsv.csvFile);
            let locservice = window.sessionStorage.getItem('sandboxId');
            formData1.append('tenantId', locservice);
            formData1.append('fhirversion', sessionStorage.getItem('version'));

            //console.log(formData1);

            //console.log(newOrg.Organization.active);
            //url = state.config.xsettings.data.sandboxManager.sandboxManagerApiUrl + "/org/" + createdOrg.id + "/csv";
            //let url=window.fhirClient.server.serviceUrl;
            // let url1=state.config.xsettings.data.sandboxManager.fhirGuiUrl+"/fhir/upload";
            let url1 = process.env.fhirGuiUrl + "/fhir/upload";

            API.post(url1, formData1, dispatch, true)
                .then(() => setTimeout(() => {
                    dispatch(setCreatedOrgCsv());
                    alert('CSV File Saved Successfully...');
                }, 550))
                .catch(() => {
                    dispatch(orgCsvCreating(false));
                });
        } else {
            dispatch(orgCsvCreating(false));
        }
    }

}