import React from "react";

const billingRoutes = [
  {
    path: "/costanalysis",
    component: React.lazy(() => import("./costanalysis"))
  },
  {
    path: "/summary",
    component: React.lazy(() => import("./summary"))
  },
  {
    path: "/invoice",
    component: React.lazy(() => import("./invoices"))
  }
];

export default billingRoutes;